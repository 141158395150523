<template>
  <v-container id="food-plan" fluid tag="section" class="custom-plan container-full-size">
    <div v-if="headerFixed && headerFixedActive" style="height: 80px"></div>
    <v-col
      cols="12"
      style="display: flex; justify-content: space-between"
      :class="{ 'header-top-fixed': headerFixed && headerFixedActive }"
      :style="{ width: headerFixedWidth }"
    >
      <v-btn v-if="hasBack || (!hasBack && (day || meal))" color="default" class="mr-0" @click="back()">
        {{ str['back'] }}
      </v-btn>
      <div v-if="editPermission && (!showSaveOnlyInitialStep || (showSaveOnlyInitialStep && !day))">
        <v-btn v-if="!hideDelete && foodPlan.id" color="error" style="margin-right: 10px" @click="openDeleteFoodPlan()">
          {{ str['delete'] }}
        </v-btn>
        <v-btn v-if="showExportPdf" color="secondary" style="margin-right: 10px" @click="exportPdf()">
          {{ str['export_pdf'] }}
        </v-btn>
        <v-btn v-if="!showSaveAsHistory || !client" color="success" class="mr-0" @click="save()">
          {{ str['save'] }}
        </v-btn>
        <v-btn v-if="showSaveAsHistory && client" color="warning" style="margin-right: 10px" @click="save()">
          {{ str['save_without_historic'] }}
        </v-btn>
        <v-btn v-if="showSaveAsHistory && client" color="success" class="mr-0" @click="saveWithHistoric()">
          {{ str['save_with_historic'] }}
        </v-btn>
        <v-btn v-if="showCopyPaste" color="blue lighten-2" style="margin-left: 10px" @click="copyClipboardPlan()">
          {{ str['copy'] }}
        </v-btn>
        <v-btn v-if="showCopyPaste && clipboardPlan" color="warning lighten-2" style="margin-left: 10px" @click="pasteClipboardPlan()">
          {{ str['paste'] }}
        </v-btn>
        <v-btn v-if="showConvertToBase && client" color="purple" style="margin-left: 10px" @click="convertPlanToBase()">
          {{ str['create_plan_base'] }}
        </v-btn>
      </div>
    </v-col>

    <v-col cols="12">
      <v-card v-if="!day" class="data-container-content">
        <v-col cols="12" class="data-container-title">
          <v-row v-if="client && (showUserNotes || (showWaterCalculation && waterCalculationValue))">
            <v-col cols="12" class="pb-0">
              <client-notes
                v-if="showUserNotes && foodDataLoaded && recipesDataLoaded"
                :client="client"
                mode="nutrition"
                :foods="foodData"
                :plan-foods="planFoods"
                :plan-recipes="planRecipes"
                :recipes="recipesItemsDict"
                :open-values-callback="openNotesValuesCallback"
              />
              <div v-if="showWaterCalculation && waterCalculationValue" cols="6" style="margin-top: 10px">
                <b>{{ str['water_to_consume'] }}: {{ waterCalculationValue }} / {{ str['day'].toLowerCase() }}</b>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="showClientDetails && client && client.feedback_day">
            <v-col :cols="12" class="pt-1 pb-0 row-align-right warning--text">
              <div>
                <b>{{ str['name'] }}:</b>
                {{ client.name }}
              </div>
              <div class="ml-2 mr-2">|</div>
              <div>
                <b>{{ str['feedback_day'] }}:</b>
                {{ client.feedback_day }}
              </div>
              <div class="ml-2 mr-2">|</div>
              <div>
                <b>{{ str['country'] }}:</b>
                {{ client.country }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-col :cols="showCategory || showCategory2 || showDateEnd ? 6 : 12">
              <v-text-field
                v-model="foodPlan.name"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['nutrition_plan_name']"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="foodPlan.name = foodPlan.name ? normalizeName(foodPlan.name) : foodPlan.name"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
                v-model="foodPlan.name_en"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['nutrition_plan_name'] + ' (EN)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="foodPlan.name_en = foodPlan.name_en ? normalizeName(foodPlan.name_en) : foodPlan.name_en"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
                v-model="foodPlan.name_es"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['nutrition_plan_name'] + ' (ES)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="foodPlan.name_es = foodPlan.name_es ? normalizeName(foodPlan.name_es) : foodPlan.name_es"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
                v-model="foodPlan.name_fr"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['nutrition_plan_name'] + ' (FR)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="foodPlan.name_fr = foodPlan.name_fr ? normalizeName(foodPlan.name_fr) : foodPlan.name_fr"
              />
            </v-col>
            <v-col v-if="showDateEnd" :cols="6" class="pt-2">
              <v-menu
                v-model="endDateDatepicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="foodPlan.date_end"
                    :label="showDateEnd.text ? (str[showDateEnd.text] ? str[showDateEnd.text] : showDateEnd.text) : str['end_date']"
                    readonly
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="foodPlan.date_end"
                  style="margin: 0"
                  :locale="datepickerLanguage"
                  @change="endDateDatepicker = false"
                />
              </v-menu>
            </v-col>
            <v-col v-if="openWithLink" cols="12">
              <v-text-field
                v-model="foodPlan.description"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['link_url']"
                class="purple-input"
                style="margin: 0"
                hide-details
              />
            </v-col>
            <v-col v-if="showCategory" :cols="showCategory2 ? 3 : 6">
              <v-autocomplete
                v-model="foodPlan.category"
                :label="categoryName ? str[categoryName] || categoryName : str['category']"
                item-text="label"
                item-value="value"
                :items="nutritionCategories"
                class="mt-0"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
            <v-col v-if="showCategory2" cols="3">
              <v-autocomplete
                v-model="foodPlan.category2"
                :label="category2Name ? str[category2Name] || category2Name : str['category']"
                item-text="label"
                item-value="value"
                :items="nutritionCategories2"
                class="mt-0"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
          </v-row>
          <div v-if="!openWithLink" class="pb-4">
            <v-textarea
              v-model="foodPlan.description"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks']"
              rows="3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
              v-model="foodPlan.description_en"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (EN)'"
              rows="3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
              v-model="foodPlan.description_es"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (ES)'"
              rows="3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
              v-model="foodPlan.description_fr"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (FR)'"
              rows="3"
              hide-details
            />
          </div>
          <div v-if="!openWithLink" class="text-right">
            <v-btn
              v-if="editPermission && !showRecipes && cloneDayCopied"
              color="orange lighten-2"
              class="mr-0"
              style="margin-right: 10px !important"
              @click="addCloneDayCopied()"
            >
              {{ str['add_day_copied'] }}
            </v-btn>
            <v-btn
              v-if="hasAutoAdjustment && editPermission && foodPlan && foodPlan.days && foodPlan.days.length"
              color="secondary"
              style="margin-right: 10px"
              @click="openAutoMealsAdjustment()"
            >
              {{ str['automatic_adjustments'] }}
            </v-btn>
            <v-btn
              v-if="client && showFormulasFirstPage && editPermission"
              color="blue"
              style="margin-right: 10px"
              @click="openFormulasDialog()"
            >
              {{ str['update_macros'] }}
            </v-btn>
            <v-btn v-if="editPermission" color="success" class="mr-0" @click="newDay()">
              {{ showRecipes ? str['add_meal'] : str['add_day'] }}
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="showPlanMacros" cols="12">
          <div>
            <div class="day-inputs-title">
              <b>
                <span>{{ str['macros'] }}</span>
                <span v-if="caloricDeficit" class="warning--text">({{ str['caloric_deficit'] }}: {{ caloricDeficit }})</span>
              </b>
            </div>
            <div class="day-inputs">
              <div v-for="(valueItem, index) in valuesItems" :key="index" @click="showFormulasFirstPage ? updateGoalValues() : null">
                <div>
                  {{ valueItem.title }}
                </div>
                <div v-if="planMacros[valueItem.currentKey]">
                  {{
                    Math.round(planMacros[valueItem.currentKey] * 100) / 100 +
                    (showFormulasFirstPage
                      ? ' / ' + foodPlan[valueItem.goalKey] + (valueItem.extraKey ? ' : ' + getMacroExtraValue(valueItem.extraKey) : '')
                      : '')
                  }}
                </div>
                <div v-if="!planMacros[valueItem.currentKey]">
                  0{{
                    showFormulasFirstPage
                      ? ' / ' + foodPlan[valueItem.goalKey] + (valueItem.extraKey ? ' : ' + getMacroExtraValue(valueItem.extraKey) : '')
                      : ''
                  }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col v-if="foodPlan.days && !openWithLink" cols="12" class="data-container-list">
          <div>
            <h4 style="margin-bottom: 10px">
              {{ showRecipes ? str['meals'] : str['days'] }}
            </h4>
          </div>
          <div v-if="!foodPlan.days || (foodPlan.days && !foodPlan.days.length)">
            {{ str['nutrition_plan_without_days'] }}
          </div>
          <draggable :list="foodPlan.days" class="sortable-list-group" handle=".handle" @start="dragging = true" @end="dragging = false">
            <div
              v-for="(itemDay, index) in foodPlan.days"
              :key="index"
              class="sortable-list-group-item sortable-list-group-item-accordion"
              :class="{
                'sortable-list-item-error': itemHasError('day', itemDay),
              }"
            >
              <div class="sortable-list-group-item-accordion-header">
                <div class="sortable-list-group-item-accordion-header-name" @click="openDay(index)">
                  <span v-if="showOverview" @click.stop="showDayOverview(itemDay)">
                    <v-icon
                      v-if="daysOverviewOpened.indexOf(itemDay.id) === -1"
                      color="primary"
                      style="margin-right: 10px"
                      class="cursor-hover"
                    >
                      mdi-chevron-down
                    </v-icon>
                    <v-icon
                      v-if="daysOverviewOpened.indexOf(itemDay.id) > -1"
                      color="primary"
                      style="margin-right: 10px"
                      class="cursor-hover"
                    >
                      mdi-chevron-up
                    </v-icon>
                  </span>
                  <span>
                    <b>{{ itemDay.name }}</b>
                    {{ showOptionsCount ? ' (' + getDayOverview(itemDay).options.length + ' ' + str['options'] + ')' : '' }}
                  </span>
                  <div v-if="!hideMacros && showDaysMacros && showMacrosAverage">
                    {{ str['calories'] }}: {{ getDayMacrosAverage(itemDay, 'calories') }} kCal | {{ str['protein'] }}:
                    {{ getDayMacrosAverage(itemDay, 'protein') }} g | {{ str['carbohydrates'] }}:
                    {{ getDayMacrosAverage(itemDay, 'carbs') }} g | {{ str['fat'] }}: {{ getDayMacrosAverage(itemDay, 'fat') }} g
                  </div>
                  <div v-if="!hideMacros && showDaysMacros && !showMacrosAverage">
                    {{ str['calories'] }}: {{ itemDay.calories }} kCal | {{ str['protein'] }}: {{ itemDay.protein }} g |
                    {{ str['carbohydrates'] }}: {{ itemDay.carbs }} g | {{ str['fat'] }}: {{ itemDay.fat }} g
                  </div>
                </div>
                <div v-if="editPermission" class="sortable-list-group-item-accordion-header-icons">
                  <v-icon v-if="!showRecipes" color="orange lighten-2" style="margin-right: 10px" @click="copyDay(index)">
                    mdi-content-copy
                  </v-icon>
                  <v-icon color="teal lighten-2" style="margin-right: 10px" @click.stop="duplicateDay(index)">
                    mdi-content-duplicate
                  </v-icon>
                  <v-icon color="error" style="margin-right: 10px" @click.stop="openDeleteDay(index)">mdi-delete</v-icon>
                  <v-icon color="default" class="handle">mdi-format-align-justify</v-icon>
                </div>
              </div>
              <div
                v-if="showOverview && daysOverviewOpened.indexOf(itemDay.id) > -1 && daysOverviewData[itemDay.id] && filterTypeEnabled"
                style="width: 100%"
              >
                <v-tabs v-model="typeOptionTab" background-color="transparent" color="secondary" grow show-arrows>
                  <v-tab v-for="tp in typesOptionsTabs" :key="tp.label">
                    {{ str[tp.label] ? str[tp.label] : tp.label }}
                  </v-tab>
                </v-tabs>
              </div>
              <div
                v-if="showOverview && daysOverviewOpened.indexOf(itemDay.id) > -1 && daysOverviewData[itemDay.id]"
                class="sortable-list-group-item-accordion-body"
              >
                <div
                  v-for="(dayOverviewOption, dayOverviewOptionIndex) in daysOverviewData[itemDay.id].options"
                  v-show="dayOverviewVisible(itemDay, dayOverviewOption)"
                  :key="dayOverviewOptionIndex"
                  class="sortable-list-group-item-accordion-body-item"
                >
                  <template v-for="(dayOverviewMeal, dayOverviewMealIndex) in daysOverviewData[itemDay.id].meals">
                    <div
                      v-if="dayOverviewMeal.option === dayOverviewOption"
                      v-show="
                        !filterTypeEnabled ||
                        (filterTypeEnabled &&
                          typesOptionsTabs[typeOptionTab] &&
                          typesOptionsTabs[typeOptionTab].values &&
                          typesOptionsTabs[typeOptionTab].values.indexOf(dayOverviewMeal.type) > -1)
                      "
                      :key="dayOverviewMealIndex"
                      :class="{
                        'sortable-list-item-error sortable-list-item-error-text': itemHasError('meal', dayOverviewMeal, itemDay),
                      }"
                      @click="openDayOverviewMeal(index, dayOverviewMealIndex)"
                    >
                      <div v-if="dayOverviewOption" class="sortable-list-group-item-accordion-body-title">
                        {{ dayOverviewOption }}
                      </div>
                      <div class="sortable-list-group-item-accordion-body-subtitle">
                        {{ dayOverviewMeal.name }}
                      </div>
                      <div class="sortable-list-group-item-accordion-body-text">
                        <div class="mt-1">
                          <b>{{ str['macros'] }}</b>
                        </div>
                        <div v-for="(valueItem, valueItemIndex) in valuesItems" :key="valueItemIndex">
                          <span>{{ valueItem.title }}:</span>
                          <span v-if="dayOverviewMeal[valueItem.currentKey]">
                            {{ dayOverviewMeal[valueItem.currentKey] }}
                          </span>
                          <span v-if="!dayOverviewMeal[valueItem.currentKey]">0</span>
                        </div>
                      </div>
                      <div class="sortable-list-group-item-accordion-body-text">
                        <div class="mt-1">
                          <b>{{ str['foods'] }}</b>
                        </div>
                        <template v-for="(dayOverviewFood, dayOverviewFoodIndex) in dayOverviewMeal.foods">
                          <div
                            v-if="foodData[dayOverviewFood.food_id]"
                            :key="dayOverviewFoodIndex"
                            :class="{
                              'sortable-list-item-error-text-color': itemHasError('food', dayOverviewFood),
                            }"
                          >
                            <span
                              v-if="
                                !foodTypeUnit[foodData[dayOverviewFood.food_id].type].disable_macros &&
                                !foodTypeUnit[foodData[dayOverviewFood.food_id].type].hide_quantity
                              "
                            >
                              • {{ dayOverviewFood.value }}{{ foodTypeUnit[foodData[dayOverviewFood.food_id].type].unit }}
                              {{ foodData[dayOverviewFood.food_id].name }}
                            </span>
                            <span
                              v-if="
                                foodTypeUnit[foodData[dayOverviewFood.food_id].type].disable_macros ||
                                foodTypeUnit[foodData[dayOverviewFood.food_id].type].hide_quantity
                              "
                            >
                              • {{ foodData[dayOverviewFood.food_id].name }}
                            </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  class="sortable-list-group-item-accordion-body-item sortable-list-group-item-accordion-body-item-new"
                  @click="addDayOverviewOption(index)"
                >
                  <v-icon class="mr-1">mdi-plus</v-icon>
                  <div>
                    {{ str['add_option'] }}
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </v-col>
      </v-card>

      <!-- Day -->
      <v-card v-if="day && !meal" class="data-container-content">
        <v-col cols="12" class="data-container-title">
          <div v-if="client && (showUserNotes || (showWaterCalculation && waterCalculationValue))" style="padding-bottom: 12px">
            <client-notes
              v-if="showUserNotes && foodDataLoaded && recipesDataLoaded"
              ref="dayNotes"
              :client="client"
              mode="nutrition"
              :foods="foodData"
              :plan-foods="planFoods"
              :plan-recipes="planRecipes"
              :recipes="recipesItemsDict"
              :open-values-callback="openNotesValuesCallback"
            />
            <div v-if="showWaterCalculation && waterCalculationValue" cols="6" class="mt-1">
              <b>{{ str['water_to_consume'] }}: {{ waterCalculationValue }} / {{ str['day'].toLowerCase() }}</b>
            </div>
          </div>
          <div>
            <v-text-field
              v-model="day.name"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="showRecipes ? str['meal'] : str['day']"
              class="purple-input"
              style="margin: 0"
              hide-details
              @blur="day.name = day.name ? normalizeName(day.name) : day.name"
            />
            <v-text-field
              v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
              v-model="day.name_en"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="(showRecipes ? str['meal'] : str['day']) + ' (EN)'"
              class="purple-input"
              style="margin: 0"
              hide-details
              @blur="day.name_en = day.name_en ? normalizeName(day.name_en) : day.name_en"
            />
            <v-text-field
              v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
              v-model="day.name_es"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="(showRecipes ? str['meal'] : str['day']) + ' (ES)'"
              class="purple-input"
              style="margin: 0"
              hide-details
              @blur="day.name_es = day.name_es ? normalizeName(day.name_es) : day.name_es"
            />
            <v-text-field
              v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
              v-model="day.name_fr"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="(showRecipes ? str['meal'] : str['day']) + ' (FR)'"
              class="purple-input"
              style="margin: 0"
              hide-details
              @blur="day.name_fr = day.name_fr ? normalizeName(day.name_fr) : day.name_fr"
            />
          </div>
          <div class="pb-4">
            <v-textarea
              v-model="day.description"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks']"
              rows="3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
              v-model="day.description_en"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (EN)'"
              rows="3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
              v-model="day.description_es"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (ES)'"
              rows="3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
              v-model="day.description_fr"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (FR)'"
              rows="3"
              hide-details
            />
          </div>
          <div v-if="!hideMacros">
            <div class="day-inputs-title">
              <b>
                <span>{{ str['macros_goal'] }}</span>
                <span v-if="caloricDeficit" class="warning--text">({{ str['caloric_deficit'] }}: {{ caloricDeficit }})</span>
              </b>
              <div>
                <v-btn v-if="client && showFormulas && editPermission" color="blue" class="mr-0" @click="openFormulasDialog()">
                  {{ str['update_macros'] }}
                </v-btn>
                <v-btn v-if="showUpdateMacros && editPermission" color="blue" class="mr-0" @click="updateGoalValues()">
                  {{ str['update_macros'] }}
                </v-btn>
              </div>
            </div>
            <div class="day-inputs">
              <div v-for="(valueItem, index) in valuesItems" :key="index" @click="updateGoalValues()">
                <div>
                  {{ valueItem.title }}
                </div>
                <div v-if="mealsOptions && mealsOptions.length && mealOptionMacros[valueItem.currentKey] && !showMacrosAverage">
                  {{ Math.round(mealOptionMacros[valueItem.currentKey] * 100) / 100 }}
                  / {{ day[valueItem.goalKey] }}{{ valueItem.extraKey ? ' : ' + getMacroExtraValue(valueItem.extraKey) : '' }}
                </div>
                <div v-if="mealsOptions && mealsOptions.length && !mealOptionMacros[valueItem.currentKey] && !showMacrosAverage">
                  0 / {{ day[valueItem.goalKey] }}{{ valueItem.extraKey ? ' : ' + getMacroExtraValue(valueItem.extraKey) : '' }}
                </div>
                <div v-if="(!mealsOptions || (mealsOptions && !mealsOptions.length)) && !showMacrosAverage">
                  {{ Math.round(day[valueItem.currentKey] * 100) / 100 }} / {{ day[valueItem.goalKey]
                  }}{{ valueItem.extraKey ? ' : ' + getMacroExtraValue(valueItem.extraKey) : '' }}
                </div>
                <div v-if="showMacrosAverage">
                  {{ getDayMacrosAverage(day, valueItem.currentKey) }} / {{ day[valueItem.goalKey]
                  }}{{ valueItem.extraKey ? ' : ' + getMacroExtraValue(valueItem.extraKey) : '' }}
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <v-btn v-if="editPermission && !hideAddMealButton" color="success" class="mr-0" @click="newMeal()">
              {{ str['add_meal'] }}
            </v-btn>
            <v-btn v-if="editPermission && showRecipesPacks" color="warning" style="margin-right: 10px" @click="newRecipesPack()">
              {{ str['add_recipes_pack'] }}
            </v-btn>
            <v-btn
              v-if="editPermission && showRecipes"
              color="success"
              class="mr-0"
              :style="{ 'margin-left': !hideAddMealButton ? '10px' : '' }"
              @click="newRecipe()"
            >
              {{ str['add_recipe'] }}
            </v-btn>
            <v-btn
              v-if="editPermission && showSauces"
              color="pink"
              class="mr-0"
              style="margin-left: 10px"
              @click="newRecipe(null, recipeCategoriesDict.sauce ? recipeCategoriesDict.sauce.value : null)"
            >
              {{ str['add_sauce'] }}
            </v-btn>
            <v-btn
              v-if="editPermission && showDesserts"
              color="purple"
              class="mr-0"
              style="margin-left: 10px"
              @click="newRecipe(null, recipeCategoriesDict.dessert ? recipeCategoriesDict.dessert.value : null)"
            >
              {{ str['add_dessert'] }}
            </v-btn>
          </div>
        </v-col>
        <v-col
          v-if="day.meals && (!showOptionsAccordion || !mealsOptions || (mealsOptions && !mealsOptions.length))"
          cols="12"
          class="data-container-list"
        >
          <div>
            <h4 style="margin-bottom: 10px">
              {{ showRecipes ? str['recipes'] : str['meals'] }}
            </h4>
          </div>
          <div v-if="mealsOptions && mealsOptions.length">
            <v-tabs
              v-model="mealOptionTab"
              background-color="transparent"
              color="secondary"
              style="margin-top: 0; margin-bottom: 10px"
              grow
              show-arrows
              @change="selectMealOption"
            >
              <v-tab v-for="opt in mealsOptions" :key="opt" :class="{ 'warning--text': !opt }">
                {{ opt ? opt : str['option_missing'].toUpperCase() }}
              </v-tab>
            </v-tabs>
          </div>
          <div v-if="!day.meals.length">
            {{ showRecipes ? str['recipes_empty'] : str['day_without_meals'] }}
          </div>
          <draggable :list="day.meals" class="sortable-list-group" handle=".handle" @start="dragging = true" @end="dragging = false">
            <div
              v-for="(itemMeal, index) in day.meals"
              v-show="
                !itemMeal.new &&
                (!mealsOptions ||
                  (mealsOptions && !mealsOptions.length) ||
                  (mealsOptions && mealsOptions.length && mealsOptions[mealOptionTab] === itemMeal.option))
              "
              :key="index"
              class="sortable-list-group-item-box"
              :class="{
                'sortable-list-item-error sortable-list-item-error-text-color': itemHasError('meal', itemMeal, day),
              }"
            >
              <div @click="openMeal(index)">
                <div>
                  <b>{{ itemMeal.name }}</b>
                </div>
                <div v-if="!hideMacros">
                  {{ str['calories'] }}: {{ itemMeal.calories }} kCal | {{ str['protein'] }}: {{ itemMeal.protein }} g |
                  {{ str['carbohydrates'] }}: {{ itemMeal.carbs }} g | {{ str['fat'] }}: {{ itemMeal.fat }} g
                </div>
              </div>
              <div v-if="editPermission">
                <v-icon color="teal lighten-2" style="margin-right: 10px" @click="duplicateMeal(index)">mdi-content-duplicate</v-icon>
                <v-icon color="error" style="margin-right: 10px" @click="deleteMeal(index)">mdi-delete</v-icon>
                <v-icon color="default" class="handle">mdi-format-align-justify</v-icon>
              </div>
            </div>
          </draggable>
        </v-col>
        <v-col v-if="day.meals && showOptionsAccordion && mealsOptions && mealsOptions.length" cols="12" class="data-container-list">
          <div>
            <h4 style="margin-bottom: 10px">
              {{ showRecipes ? str['recipes'] : str['meals'] }}
            </h4>
          </div>
          <div v-if="filterTypeEnabled">
            <v-tabs
              v-model="typeOptionTab"
              background-color="transparent"
              color="secondary"
              style="margin-top: 0; margin-bottom: 10px"
              grow
              show-arrows
              @change="selectMealType"
            >
              <v-tab v-for="tp in typesOptionsTabs" :key="tp.label">
                {{ str[tp.label] ? str[tp.label] : tp.label }}
              </v-tab>
            </v-tabs>
          </div>
          <v-expansion-panels v-model="optionsAccordionActive" multiple>
            <v-expansion-panel
              v-for="(optItem, index) in mealsOptions"
              v-show="
                day.meals.some(
                  (meal) =>
                    !meal.new &&
                    meal.option === optItem &&
                    (!filterTypeEnabled ||
                      (filterTypeEnabled &&
                        typesOptionsTabs[typeOptionTab] &&
                        typesOptionsTabs[typeOptionTab].values &&
                        typesOptionsTabs[typeOptionTab].values.indexOf(meal.type) > -1)),
                )
              "
              :key="index"
              style="margin: 1px"
              class="accordion-plan"
              @change="selectMealOption"
            >
              <v-expansion-panel-header style="padding: 5px 16px; min-height: 30px">
                <div class="accordion-plan__header">
                  <div>
                    {{ optItem }}
                  </div>
                  <div v-if="index > 0 || index !== mealsOptions.length - 1">
                    <v-icon
                      v-if="index > 0"
                      color="primary"
                      style="margin-right: 10px"
                      class="cursor-hover"
                      @click.stop="moveAccordion('up', optItem)"
                    >
                      mdi-arrow-up
                    </v-icon>
                    <v-icon
                      v-if="index !== mealsOptions.length - 1"
                      color="primary"
                      style="margin-right: 10px"
                      class="cursor-hover"
                      @click.stop="moveAccordion('down', optItem)"
                    >
                      mdi-arrow-down
                    </v-icon>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content style="padding: 0 0 10px">
                      <draggable
                        :list="day.meals"
                        class="sortable-list-group"
                        handle=".handle"
                        @start="dragging = true"
                        @end="dragging = false"
                      >
                        <div
                          v-for="(itemMeal, itemMealIndex) in day.meals"
                          v-show="
                            !itemMeal.new &&
                            itemMeal.option === optItem &&
                            (!filterTypeEnabled ||
                              (filterTypeEnabled &&
                                typesOptionsTabs[typeOptionTab] &&
                                typesOptionsTabs[typeOptionTab].values &&
                                typesOptionsTabs[typeOptionTab].values.indexOf(itemMeal.type) > -1))
                          "
                          :key="itemMealIndex"
                          class="sortable-list-group-item-box"
                          :class="{
                            'sortable-list-item-error sortable-list-item-error-text-color': itemHasError('meal', itemMeal, day),
                            'sortable-list-group-item-box-many-icons': showUpdateRecipeIcon,
                          }"
                        >
                          <div @click="openMeal(itemMealIndex)">
                            <div>
                              <b>{{ itemMeal.name }}</b>
                            </div>
                            <div class="mt-2">
                              {{ str['calories'] }}: {{ itemMeal.calories }} kCal | {{ str['protein'] }}: {{ itemMeal.protein }} g |
                              {{ str['carbohydrates'] }}: {{ itemMeal.carbs }} g | {{ str['fat'] }}: {{ itemMeal.fat }} g
                            </div>
                            <div v-if="itemMeal.foods" class="mt-2 text-small">
                              <b>{{ str['foods'] }}:</b>
                              <template v-for="(mealFood, indexMealFood) in itemMeal.foods">
                                <span
                                  v-if="foodData[mealFood.food_id]"
                                  :key="indexMealFood"
                                  :class="{
                                    'sortable-list-item-error sortable-list-item-error-text-color': itemHasError('food', mealFood),
                                  }"
                                >
                                  <span
                                    v-if="
                                      !foodTypeUnit[foodData[mealFood.food_id].type].disable_macros &&
                                      !foodTypeUnit[foodData[mealFood.food_id].type].hide_quantity
                                    "
                                  >
                                    {{ mealFood.value }}{{ foodTypeUnit[foodData[mealFood.food_id].type].unit }}
                                    {{ normalizeName(foodData[mealFood.food_id].name)
                                    }}{{ indexMealFood < itemMeal.foods.length - 1 ? '; ' : '' }}
                                  </span>
                                  <span
                                    v-if="
                                      foodTypeUnit[foodData[mealFood.food_id].type].disable_macros ||
                                      foodTypeUnit[foodData[mealFood.food_id].type].hide_quantity
                                    "
                                  >
                                    {{ normalizeName(foodData[mealFood.food_id].name)
                                    }}{{ indexMealFood < itemMeal.foods.length - 1 ? '; ' : '' }}
                                  </span>
                                </span>
                              </template>
                            </div>
                            <div v-if="showSupplements && getMealSupplements(itemMeal)" class="mt-2 text-small">
                              <b>{{ str['supplements'] }}:</b>
                              <template v-for="(mealSupplement, indexMealSupplement) in getMealSupplements(itemMeal)">
                                <span
                                  v-if="supplementData[mealSupplement.id]"
                                  :key="indexMealSupplement"
                                  :class="{
                                    'sortable-list-item-error sortable-list-item-error-text-color': itemHasError(
                                      'supplement',
                                      mealSupplement,
                                    ),
                                  }"
                                >
                                  {{ mealSupplement.value }}
                                  {{ supplementData[mealSupplement.id].name }}
                                </span>
                              </template>
                            </div>
                          </div>
                          <div v-if="editPermission">
                            <v-icon
                              v-if="foodDataLoaded && recipesDataLoaded && showUpdateRecipeIcon"
                              color="blue lighten-2"
                              style="margin-right: 10px"
                              @click="updateRecipe(itemMealIndex)"
                            >
                              mdi-swap-vertical-circle
                            </v-icon>
                            <v-icon
                              v-if="
                                showUserNotes &&
                                client &&
                                foodDataLoaded &&
                                recipesDataLoaded &&
                                showExcludeRecipeIcon &&
                                errorsRecipes.indexOf(normalizeName(itemMeal.name)) > -1
                              "
                              color="success"
                              style="margin-right: 10px"
                              @click="setExcludeRecipe(itemMeal, false)"
                            >
                              mdi-food
                            </v-icon>
                            <v-icon
                              v-if="
                                showUserNotes &&
                                client &&
                                foodDataLoaded &&
                                recipesDataLoaded &&
                                showExcludeRecipeIcon &&
                                errorsRecipes.indexOf(normalizeName(itemMeal.name)) === -1
                              "
                              color="warning"
                              style="margin-right: 10px"
                              @click="setExcludeRecipe(itemMeal, true)"
                            >
                              mdi-food-off
                            </v-icon>
                            <v-icon color="error" style="margin-right: 10px" @click="deleteMeal(itemMealIndex)">mdi-delete</v-icon>
                            <v-icon color="default" class="handle">mdi-format-align-justify</v-icon>
                          </div>
                        </div>
                      </draggable>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-card>

      <!-- Meal -->
      <v-card v-if="meal" class="data-container-content">
        <v-col cols="12" class="data-container-title">
          <v-row v-if="client && (showUserNotes || (showWaterCalculation && waterCalculationValue))">
            <v-col cols="12" class="pb-0">
              <client-notes
                v-if="showUserNotes && foodDataLoaded && recipesDataLoaded"
                ref="mealNotes"
                :client="client"
                mode="nutrition"
                :foods="foodData"
                :plan-foods="planFoods"
                :plan-recipes="planRecipes"
                :recipes="recipesItemsDict"
                :open-values-callback="openNotesValuesCallback"
              />
              <div v-if="showWaterCalculation && waterCalculationValue" cols="6" class="mt-1">
                <b>{{ str['water_to_consume'] }}: {{ waterCalculationValue }} / {{ str['day'].toLowerCase() }}</b>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="showMealOption ? 6 : 12">
              <v-text-field
                v-model="meal.name"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="showRecipes ? str['recipe'] : str['meal']"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="meal.name = meal.name ? normalizeName(meal.name) : meal.name"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
                v-model="meal.name_en"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="(showRecipes ? str['recipe'] : str['meal']) + ' (EN)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="meal.name_en = meal.name_en ? normalizeName(meal.name_en) : meal.name_en"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
                v-model="meal.name_es"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="(showRecipes ? str['recipe'] : str['meal']) + ' (ES)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="meal.name_es = meal.name_es ? normalizeName(meal.name_es) : meal.name_es"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
                v-model="meal.name_fr"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="(showRecipes ? str['recipe'] : str['meal']) + ' (FR)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="meal.name_fr = meal.name_fr ? normalizeName(meal.name_fr) : meal.name_fr"
              />
            </v-col>
            <v-col v-if="showMealOption" cols="6">
              <v-text-field
                v-model="meal.option"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['option']"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="meal.option = meal.option ? normalizeName(meal.option) : meal.option"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
                v-model="meal.option_en"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['option'] + ' (EN)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="meal.option_en = meal.option_en ? normalizeName(meal.option_en) : meal.option_en"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
                v-model="meal.option_es"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['option'] + ' (ES)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="meal.option_es = meal.option_es ? normalizeName(meal.option_es) : meal.option_es"
              />
              <v-text-field
                v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
                v-model="meal.option_fr"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['option'] + ' (FR)'"
                class="purple-input"
                style="margin: 0"
                hide-details
                @blur="meal.option_fr = meal.option_fr ? normalizeName(meal.option_fr) : meal.option_fr"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-if="showMealVideo"
            v-model="meal.video_url"
            :label="str['video']"
            class="purple-input"
            style="margin: 0"
            @blur="meal.video_url = meal.video_url ? meal.video_url.trim() : meal.video_url"
          />
          <div v-if="showMealImage" style="display: flex; padding-bottom: 15px">
            <div class="input-image-field">
              <h4>
                {{ str['image'] }}
              </h4>
              <div class="cursor-hover">
                <v-img v-if="meal.image && meal.image.image" :src="meal.image.image" style="width: 100%; height: 100%" contain />
                <v-icon v-if="!meal.image || (meal.image && !meal.image.image)" color="success" size="40">mdi-plus-circle</v-icon>
                <input id="meal-input-image" type="file" accept=".png,.jpg,.jpeg" @change="updateMealImage" />
              </div>
              <v-icon
                v-if="meal.image && meal.image.image"
                color="error"
                size="30"
                class="cursor-hover input-image-field-remove"
                @click="updateMealImage(null)"
              >
                mdi-close-circle
              </v-icon>
            </div>
            <div style="width: 100%; padding-left: 15px">
              <v-textarea
                v-model="meal.description"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
                :label="str['remarks']"
                rows="3"
                hide-details
              />
            </div>
          </div>
          <div v-if="!showMealImage" class="pb-4" :class="{ 'pt-4': hideFoods }">
            <v-textarea
              v-model="meal.description"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks']"
              :rows="hideFoods ? 10 : 3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('en') > -1"
              v-model="meal.description_en"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (EN)'"
              :rows="hideFoods ? 10 : 3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('es') > -1"
              v-model="meal.description_es"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (ES)'"
              :rows="hideFoods ? 10 : 3"
              hide-details
            />
            <v-textarea
              v-if="hasTranslations && hasTranslations.indexOf('fr') > -1"
              v-model="meal.description_fr"
              :style="{
                'pointer-events': editPermission ? '' : 'none',
              }"
              :label="str['remarks'] + ' (FR)'"
              :rows="hideFoods ? 10 : 3"
              hide-details
            />
          </div>
          <div v-if="showPlanMacros || showMealOptionsMacros" class="mb-2">
            <div v-if="!showMealOptionsMacros" class="day-inputs-title">
              <b>{{ str['macros'] }}</b>
            </div>
            <div v-if="showMealOptionsMacros && daysOverviewData[day.id]">
              <div
                v-for="(optMeal, optMealIndex) in daysOverviewData[day.id].meals"
                v-show="
                  (showPlanMacros &&
                    (!filterTypeEnabled ||
                      (filterTypeEnabled &&
                        typesOptionsTabs[typeOptionTab] &&
                        typesOptionsTabs[typeOptionTab].values &&
                        typesOptionsTabs[typeOptionTab].values.indexOf(optMeal.type) > -1))) ||
                  (!showPlanMacros && meal.name === optMeal.name) ||
                  showAllMacrosInMeal
                "
                :key="optMealIndex"
              >
                <div
                  :class="{
                    'success--text':
                      (!showAllMacrosInMeal &&
                        meal.option === optMeal.option &&
                        (!filterTypeEnabled || (filterTypeEnabled && meal.type === optMeal.type))) ||
                      (showAllMacrosInMeal && meal.name === optMeal.name),
                    'sortable-list-item-error-text-color': itemHasError('meal', optMeal, day),
                  }"
                  class="cursor-hover"
                  @click="openDayOverviewMeal(null, optMealIndex)"
                >
                  <b>{{ optMeal.option + (optMeal.name ? ' - ' + optMeal.name : '') }}</b>
                </div>
                <div class="day-inputs">
                  <div
                    v-for="(valueItem, index) in valuesItems"
                    :key="index"
                    :class="{
                      'sortable-list-item-error-text-color':
                        errorsDaysMacros[day.id] &&
                        errorsDaysMacros[day.id].keys &&
                        errorsDaysMacros[day.id].keys.indexOf(valueItem.currentKey) > -1,
                    }"
                  >
                    <div>
                      {{ valueItem.title }}
                    </div>
                    <div v-if="optMeal[valueItem.currentKey]">
                      {{ optMeal[valueItem.currentKey] }}
                    </div>
                    <div v-if="!optMeal[valueItem.currentKey]">0</div>
                  </div>
                </div>
              </div>
              <div v-if="errorsDaysMacros[day.id] && errorsDaysMacros[day.id].errors" class="sortable-list-item-error-text-color pt-2">
                {{ errorsDaysMacros[day.id].errors.join(' | ') }}
              </div>
            </div>
            <div v-if="!showMealOptionsMacros" class="day-inputs">
              <div v-for="(valueItem, index) in valuesItems" :key="index">
                <div>
                  {{ valueItem.title }}
                </div>
                <div v-if="meal[valueItem.currentKey]">
                  {{ meal[valueItem.currentKey] }}
                </div>
                <div v-if="!meal[valueItem.currentKey]">0</div>
              </div>
            </div>
          </div>
          <div v-if="!hideFoods" class="text-right">
            <v-btn
              v-if="editPermission && showCopyFood && cloneFoodCopied"
              color="orange lighten-2"
              class="mr-0"
              style="margin-right: 10px !important"
              @click="addCloneFoodCopied()"
            >
              {{ str['add_food_copied'] }}
            </v-btn>
            <v-btn v-if="showRecipes && editPermission" color="warning" style="margin-right: 10px" @click="newRecipe(true)">
              {{ str['update_recipe'] }}
            </v-btn>
            <v-btn v-if="editPermission" color="success" class="mr-0" @click="newFood()">
              {{ str['add_food'] }}
            </v-btn>
            <v-btn
              v-if="showSupplements && editPermission"
              color="blue"
              class="mr-0"
              style="margin-left: 10px"
              @click="openAddSupplementDialog()"
            >
              {{ str['add_supplement'] }}
            </v-btn>
          </div>
        </v-col>
        <div v-if="!hideFoods" class="row-flex-responsive mb-4">
          <v-col v-if="meal.foods" :cols="showSupplements ? 6 : 12" class="data-container-list">
            <div>
              <h4 style="margin-bottom: 10px">
                {{ str['foods'] }}
              </h4>
            </div>
            <div v-if="!meal.foods.length">
              {{ str['meal_without_foods'] }}
            </div>
            <draggable :list="meal.foods" class="sortable-list-group" handle=".handle" @start="dragging = true" @end="dragging = false">
              <div
                v-for="(itemFood, index) in meal.foods"
                v-show="!itemFood.new"
                :key="index"
                class="sortable-list-group-item-box"
                :class="{
                  'sortable-list-item-error sortable-list-item-error-text-color': itemHasError('food', itemFood),
                }"
                :style="{
                  'border-color':
                    foodData[itemFood.food_id] &&
                    foodCategoriesDict[foodData[itemFood.food_id].category] &&
                    foodCategoriesDict[foodData[itemFood.food_id].category].color
                      ? foodCategoriesDict[foodData[itemFood.food_id].category].color
                      : '',
                  'background-color':
                    foodData[itemFood.food_id] &&
                    foodCategoriesDict[foodData[itemFood.food_id].category] &&
                    foodCategoriesDict[foodData[itemFood.food_id].category].background_color
                      ? foodCategoriesDict[foodData[itemFood.food_id].category].background_color + '!important'
                      : '',
                }"
              >
                <div
                  :class="{
                    'sortable-list-group-item-box-content-large': showExcludeFoodIcon && client && errorsFoodsIds,
                  }"
                  @click="openFood(index)"
                >
                  <div v-if="foodData[itemFood.food_id]">
                    <b
                      v-if="
                        !foodTypeUnit[foodData[itemFood.food_id].type].disable_macros &&
                        !foodTypeUnit[foodData[itemFood.food_id].type].hide_quantity
                      "
                    >
                      {{ itemFood.value }}{{ foodTypeUnit[foodData[itemFood.food_id].type].unit }}
                      {{ foodData[itemFood.food_id].name }}
                    </b>
                    <b
                      v-if="
                        foodTypeUnit[foodData[itemFood.food_id].type].disable_macros ||
                        foodTypeUnit[foodData[itemFood.food_id].type].hide_quantity
                      "
                    >
                      {{ foodData[itemFood.food_id].name }}
                    </b>
                  </div>
                  <div
                    v-if="
                      !foodData[itemFood.food_id] ||
                      (foodData[itemFood.food_id] && !foodTypeUnit[foodData[itemFood.food_id].type].disable_macros)
                    "
                  >
                    {{ str['calories'] }}: {{ itemFood.calories }} kCal | {{ str['protein'] }}: {{ itemFood.protein }} g |
                    {{ str['carbohydrates'] }}: {{ itemFood.carbs }} g | {{ str['fat'] }}: {{ itemFood.fat }} g
                  </div>
                  <div v-if="showDetails">
                    {{ itemFood.details }}
                  </div>
                  <div v-if="hasAlternatives && itemFood.alternatives" class="warning--text" style="font-size: 12px">
                    {{ itemFood.alternatives }}
                  </div>
                  <div v-if="hasAlternativesExtra && itemFood.alternatives_extra" class="warning--text" style="font-size: 12px">
                    {{ JSON.parse(itemFood.alternatives_extra).join(', ') }}
                  </div>
                </div>
                <div v-if="editPermission">
                  <v-icon
                    v-if="showExcludeFoodIcon && client && errorsFoodsIds && errorsFoodsIds.indexOf(itemFood.food_id) > -1"
                    color="success"
                    style="margin-right: 10px"
                    @click="setExcludeFood(itemFood.food_id, false)"
                  >
                    mdi-food
                  </v-icon>
                  <v-icon
                    v-if="showExcludeFoodIcon && client && errorsFoodsIds && errorsFoodsIds.indexOf(itemFood.food_id) === -1"
                    color="warning"
                    style="margin-right: 10px"
                    @click="setExcludeFood(itemFood.food_id, true)"
                  >
                    mdi-food-off
                  </v-icon>
                  <v-icon v-if="showCopyFood" color="orange lighten-2" style="margin-right: 10px" @click="copyFood(itemFood)">
                    mdi-content-copy
                  </v-icon>
                  <v-icon color="error" style="margin-right: 10px" @click="deleteFood(index)">mdi-delete</v-icon>
                  <v-icon color="default" class="handle">mdi-format-align-justify</v-icon>
                </div>
              </div>
            </draggable>
          </v-col>
          <v-col v-if="showSupplements && getMealSupplements(meal)" cols="6" class="data-container-list">
            <div>
              <h4 style="margin-bottom: 10px">
                {{ str['supplements'] }}
              </h4>
            </div>
            <draggable class="sortable-list-group" handle=".handle" @start="dragging = true" @end="sortMealSupplements">
              <div
                v-for="(supplement, index) in getMealSupplements(meal)"
                :key="index"
                class="sortable-list-group-item-box"
                :class="{
                  'sortable-list-item-error sortable-list-item-error-text-color': itemHasError('supplement', supplement),
                }"
              >
                <div>
                  <div v-if="supplementData[supplement.id]">
                    <b>{{ supplement.value }} {{ supplementData[supplement.id].name }}</b>
                  </div>
                </div>
                <div v-if="editPermission">
                  <v-icon color="error" style="margin-right: 10px" @click="deleteSupplement(index)">mdi-delete</v-icon>
                  <v-icon color="default" class="handle">mdi-format-align-justify</v-icon>
                </div>
              </div>
            </draggable>
          </v-col>
        </div>

        <v-dialog v-if="food" v-model="dialogFood" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ str['add_food'] }}
              </span>
            </v-card-title>
            <v-card-text style="padding-top: 10px; padding-bottom: 10px">
              <v-container style="padding-top: 0; padding-bottom: 0">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-if="showFoodParentCategoryFilter"
                      v-model="foodParentCategoryFilter"
                      :label="str['type']"
                      item-text="label"
                      item-value="value"
                      :items="foodParentCategoriesList"
                      :no-data-text="str['no_data']"
                      clearable
                    />
                    <v-autocomplete
                      v-if="hasFoodCategory"
                      v-model="foodCategoryFilter"
                      :label="str['category']"
                      item-text="label"
                      item-value="value"
                      :items="foodCategoriesList"
                      :no-data-text="str['no_data']"
                      multiple
                      clearable
                    />
                    <v-autocomplete
                      v-model="food.food_id"
                      :label="str['food']"
                      item-text="name"
                      item-value="id"
                      :items="foodsItemsFiltered"
                      :no-data-text="str['no_data']"
                      :filter="customFoodsFilter"
                      @change="changeAddFood"
                    >
                      <template #item="{ item, on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>
                              <div>
                                <div>
                                  {{ normalizeName(item.name) }}
                                </div>
                                <v-divider v-if="item.separator" style="position: absolute; bottom: 0; left: 0; width: 100%" />
                              </div>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <h5>
                      {{ foodData[food.food_id] ? foodTypeUnit[foodData[food.food_id].type].label : str['value'] }}
                    </h5>
                    <v-text-field
                      v-if="
                        foodData[food.food_id] &&
                        foodTypeUnit[foodData[food.food_id].type].value === 1 &&
                        !foodTypeUnit[foodData[food.food_id].type].disable_macros &&
                        !foodTypeUnit[foodData[food.food_id].type].hide_quantity
                      "
                      v-model="food.value"
                      required
                      class="pt-0 mt-0"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    />
                    <v-text-field
                      v-if="
                        foodData[food.food_id] &&
                        foodTypeUnit[foodData[food.food_id].type].value !== 1 &&
                        !foodTypeUnit[foodData[food.food_id].type].disable_macros &&
                        !foodTypeUnit[foodData[food.food_id].type].hide_quantity
                      "
                      v-model="food.value"
                      required
                      class="pt-0 mt-0"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    />
                    <v-text-field
                      v-if="
                        !foodData[food.food_id] ||
                        (foodData[food.food_id] &&
                          (foodTypeUnit[foodData[food.food_id].type].disable_macros ||
                            foodTypeUnit[foodData[food.food_id].type].hide_quantity))
                      "
                      v-model="food.value"
                      :disabled="
                        foodData[food.food_id] &&
                        (foodTypeUnit[foodData[food.food_id].type].disable_macros ||
                          foodTypeUnit[foodData[food.food_id].type].hide_quantity)
                      "
                      required
                      class="pt-0 mt-0"
                    />
                    <div v-if="hasAlternativesExtra">
                      <h5>
                        {{ str['alternatives'] }}
                      </h5>
                      <div>
                        <v-chip
                          v-for="(alternativeExtra, alternativeExtraIndex) in food.alternatives_extra"
                          :key="alternativeExtraIndex"
                          class="ma-2"
                          close
                          @click:close="food.alternatives_extra.splice(alternativeExtraIndex, 1)"
                        >
                          {{ alternativeExtra }}
                        </v-chip>
                        <v-chip class="ma-2" color="green" @click="newAlternativeExtra()">
                          {{ str['add'] }}
                        </v-chip>
                      </div>
                    </div>
                    <v-textarea v-if="showDetails" v-model="food.details" :label="str['remarks']" hide-details />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions style="padding-top: 0">
              <v-spacer></v-spacer>
              <v-btn color="default" @click="cancelFood">
                {{ str['cancel'] }}
              </v-btn>
              <v-btn :disabled="!food.food_id" color="success" @click="saveFood">
                {{ str['save'] }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-if="showSupplements && meal" v-model="dialogAddSupplement" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ str['add_supplement'] }}
              </span>
            </v-card-title>
            <v-card-text style="padding-top: 10px; padding-bottom: 10px">
              <v-container style="padding-top: 0; padding-bottom: 0">
                <v-row>
                  <v-col cols="12" style="min-height: 300px">
                    <v-autocomplete
                      v-model="supplementToAdd.id"
                      :label="str['supplement']"
                      item-text="name"
                      item-value="id"
                      :items="supplementsItems"
                      :no-data-text="str['no_data']"
                    />
                    <v-text-field v-model="supplementToAdd.value" :label="str['value']" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions style="padding-top: 0">
              <v-spacer></v-spacer>
              <v-btn color="default" @click="closeAddSupplementDialog">
                {{ str['cancel'] }}
              </v-btn>
              <v-btn color="success" @click="addSupplement">
                {{ str['save'] }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>

    <v-dialog v-if="showRecipes" v-model="dialogRecipe" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ updateMealRecipe ? str['update_recipe'] : str['add_recipe'] }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col v-if="recipeParentCategories && recipeParentCategories.length" cols="12">
                <v-autocomplete
                  v-model="recipeParentCategoryFilter"
                  :label="str['filter']"
                  item-text="label"
                  item-value="value"
                  :items="recipeParentCategories"
                  :no-data-text="str['no_data']"
                  hide-details
                />
              </v-col>
              <v-col v-if="recipeCategories && recipeCategories.length" cols="12">
                <v-autocomplete
                  v-model="recipeCategoryFilter"
                  :label="str['category']"
                  item-text="label"
                  item-value="value"
                  :items="recipeCategories"
                  :no-data-text="str['no_data']"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="recipeSelected"
                  :label="str['recipe']"
                  item-text="name"
                  item-value="id"
                  :items="recipesItemsFiltered"
                  :no-data-text="str['no_data']"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="cancelRecipe">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn :disabled="!recipeSelected" color="success" @click="saveRecipe">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRecipesPack" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['add_recipes_pack'] }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="recipesPackSelected"
                  :label="str['recipes_pack']"
                  item-text="name"
                  item-value="id"
                  :items="recipesPacksItems"
                  :no-data-text="str['no_data']"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="cancelNewRecipesPack">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn :disabled="!recipesPackSelected" color="success" @click="saveNewRecipesPack">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="meal && warningBackMessage" v-model="dialogWarningBackMessage" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="warning--text">
            {{ str[warningBackMessage] ? str[warningBackMessage] : warningBackMessage }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="meal.name"
                  :label="showRecipes ? str['recipe'] : str['meal']"
                  class="purple-input"
                  style="margin: 0"
                  @blur="meal.name = meal.name ? normalizeName(meal.name) : meal.name"
                />
                <v-textarea v-model="meal.description" :label="str['remarks']" rows="3" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmBack">
            {{ str['confirm'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showExcludeRecipeIcon && excludeRecipeData" v-model="dialogExcludeRecipe" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['description'] }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="excludeRecipeData.description">
                  <v-radio
                    v-for="(descriptionItem, descriptionItemIndex) in excludeRecipeData.items"
                    :key="descriptionItemIndex"
                    :label="str[descriptionItem.label] ? str[descriptionItem.label] : descriptionItem.label"
                    :value="descriptionItem.value"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="excludeRecipeData.cancelCallback">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" :disabled="!excludeRecipeData.description" @click="excludeRecipeData.confirmCallback">
            {{ str['confirm'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="hasAutoAdjustment && autoMealsAdjustmentData" v-model="dialogAutoMealsAdjustment" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['automatic_adjustments'] }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col v-for="(item, index) in autoMealsAdjustmentData" :key="index" cols="6">
                <div class="label-parent pt-0 pb-0">
                  {{ item.name }}
                </div>
                <div v-if="item.info" class="text-small mb-3" v-html="item.info"></div>
                <v-text-field
                  v-model="item.value"
                  :label="str['calories_kcal']"
                  required
                  class="pt-0 mt-0"
                  style="max-width: 100px"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" @click="closeAutoMealsAdjustment">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn :disabled="!autoMealsAdjustmentValid()" color="success" @click="applyAutoMealsAdjustment">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialogSelectDay && dialogSelectDayCallback" v-model="dialogSelectDay" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['select_meal'] }}
          </span>
        </v-card-title>
        <v-card-text style="height: 500px; padding: 10px 0">
          <div
            v-for="(itemDay, itemDayIndex) in foodPlan.days"
            :key="itemDayIndex"
            class="box-select-item"
            @click="dialogSelectDayCallback(itemDay, itemDayIndex)"
          >
            {{ itemDay.name }} -
            {{ itemDay.meals.filter((i) => i.type === 0).length }}
            {{ str['options'] }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn color="default" @click="closeDialogSelectDay">
            {{ str['cancel'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGoalValues" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['update_macros'] }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-for="(valueItem, index) in valuesItems"
                  v-show="!valueItem.hidden"
                  :key="index"
                  v-model="goalValues[valueItem.goalKey]"
                  :label="valueItem.title"
                  required
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="cancelGoalValues">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="saveGoalValues">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <client-formulas
      ref="formulas"
      :client="client"
      :food-plan="foodPlan"
      :day="day"
      :refresh-callback="refreshClientFormulas"
      :mounted-callback="clientFormulasMounted"
    />

    <client-feedback ref="feedback" :client="client" :mounted-callback="clientFeedbackMounted" :destroy-callback="clientFeedbackDestroy" />
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import draggable from 'vuedraggable'
import html2pdf from 'html2pdf.js'
import moment from 'moment'

export default {
  components: {
    draggable,
    ClientNotes: () => import('@/components/dashboard/client/Notes'),
    ClientFeedback: () => import('@/components/dashboard/client/Feedback'),
    ClientFormulas: () => import('@/components/dashboard/client/Formulas'),
  },
  props: {
    client: Object,
    plan: Object,
    baseUsed: Boolean,
    headerFixed: Boolean,
    hasBack: Boolean,
    hideDelete: Boolean,
    mountedCallback: Function,
  },
  data() {
    const user = Utils.getUser()
    const editPermission = Utils.hasPermission('nutrition_edit')

    let showDetails = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_food_details) {
      showDetails = true
    }

    let showMealVideo = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_meal_video) {
      showMealVideo = true
    }

    let showMealImage = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_meal_photo) {
      showMealImage = true
    }

    let showSupplements = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.supplements) {
      showSupplements = true
    }

    let showFormulas = false
    let showUpdateMacros = true
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_formulas) {
      showFormulas = true
      showUpdateMacros = false
    }

    let showFormulasFirstPage = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_formulas_first_page) {
      showFormulasFirstPage = true
    }

    let showMealOption = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_meal_options) {
      showMealOption = true
    }

    let showRecipes = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_recipes) {
      showRecipes = true
    }

    let showRecipesPacks = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_recipes_packs) {
      showRecipesPacks = true
    }

    let showOptionsAccordion = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_options_accordion) {
      showOptionsAccordion = true
    }

    let showOnlyPage = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_only_page) {
      showOnlyPage = true
    }

    let showPlanMacros = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_plan_macros) {
      showPlanMacros = true
    }

    let showAllMacrosInMeal = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_all_macros_in_meal) {
      showAllMacrosInMeal = true
    }

    let showMealOptionsMacros = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_meal_options_macros) {
      showMealOptionsMacros = true
    }

    let showCategory = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_category) {
      showCategory = true
    }

    let categoryName = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.category_name) {
      categoryName = user.configurations.food_plans.category_name
    }

    let showCategory2 = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_category2) {
      showCategory2 = true
    }

    let category2Name = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.category2_name) {
      category2Name = user.configurations.food_plans.category2_name
    }

    let showUserNotes = false
    if (
      user &&
      user.configurations &&
      user.configurations.food_plans &&
      user.configurations.food_plans.show_user_notes &&
      Utils.hasPermission('user_notes_view')
    ) {
      showUserNotes = true
    }

    let showWaterCalculation = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_water_calculation) {
      showWaterCalculation = true
    }

    let showOverview = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_overview) {
      showOverview = true
    }

    let hasValidation = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_validation) {
      hasValidation = true
    }

    let hasFoodsExcludedCountriesValidation = false
    if (
      user &&
      user.configurations &&
      user.configurations.food_plans &&
      user.configurations.food_plans.has_foods_excluded_countries_validation
    ) {
      hasFoodsExcludedCountriesValidation = true
    }

    let hasFoodsIntolerancesValidation = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_foods_intolerances_validation) {
      hasFoodsIntolerancesValidation = true
    }

    let hasAcceptedFoodsValidation = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_accepted_foods_validation) {
      hasAcceptedFoodsValidation = true
    }

    let hasRepeatedFoodsValidation = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_repeated_foods_validation) {
      hasRepeatedFoodsValidation = true
    }

    let hasRepeatedRecipesValidation = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_repeated_recipes_validation) {
      hasRepeatedRecipesValidation = true
    }

    let hasMacrosValidation = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_macros_validation) {
      hasMacrosValidation = user.configurations.food_plans.has_macros_validation
    }

    let hasAutoAdjustment = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_auto_adjustment) {
      hasAutoAdjustment = true
    }

    let showSaveAsHistory = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_save_as_history) {
      showSaveAsHistory = true
    }

    let hasAutoFillOption = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_auto_fill_option) {
      hasAutoFillOption = true
    }

    let warningSaveMessage = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.warning_save_message) {
      warningSaveMessage = user.configurations.food_plans.warning_save_message
    }

    let preventRecipeChanges = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.prevent_recipe_changes) {
      preventRecipeChanges = user.configurations.food_plans.prevent_recipe_changes
    }

    let warningBackMessage = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.warning_back_message) {
      warningBackMessage = user.configurations.food_plans.warning_back_message
    }

    let showExitWarning = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_exit_warning) {
      showExitWarning = user.configurations.food_plans.show_exit_warning
    }

    let openWithLink = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.open_with_link) {
      openWithLink = true
    }

    let showSaveOnlyInitialStep = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_save_only_initial_step) {
      showSaveOnlyInitialStep = true
    }

    let showCopyPaste = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_copy_paste) {
      showCopyPaste = true
    }

    let showDesserts = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_desserts) {
      showDesserts = true
    }

    let showSauces = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_sauces) {
      showSauces = true
    }

    let showConvertToBase = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_convert_to_base) {
      showConvertToBase = true
    }

    let showFoodsSortedByClient = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_foods_sorted_by_client) {
      showFoodsSortedByClient = true
    }

    let showFoodsDisabledByClient = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_foods_disabled_by_client) {
      showFoodsDisabledByClient = true
    }

    let showExcludeRecipeIcon = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_exclude_recipe_icon) {
      showExcludeRecipeIcon = true
    }

    let showUpdateRecipeIcon = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_update_recipe_icon) {
      showUpdateRecipeIcon = true
    }

    let hasAlternatives = false
    if (user && user.configurations && user.configurations.food && user.configurations.food.has_alternatives) {
      hasAlternatives = true
    }

    let hasFoodCategory = false
    if (user && user.configurations && user.configurations.food && user.configurations.food.has_category) {
      hasFoodCategory = true
    }

    let hasFoodCategory2 = false
    if (user && user.configurations && user.configurations.food && user.configurations.food.has_category2) {
      hasFoodCategory2 = true
    }

    let showCopyFood = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_copy_food) {
      showCopyFood = true
    }

    let showHeaderFixed = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_header_fixed) {
      showHeaderFixed = true
    }

    let showExcludeFoodIcon = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_exclude_food_icon) {
      showExcludeFoodIcon = true
    }

    let sortRecipesByAcceptedFoods = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.sort_recipes_by_accepted_foods) {
      sortRecipesByAcceptedFoods = true
    }

    let hideAddMealButton = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.hide_add_meal_button) {
      hideAddMealButton = true
    }

    let hasTranslations = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_translations) {
      hasTranslations = user.configurations.food_plans.has_translations
    }

    let hideMacros = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.hide_macros) {
      hideMacros = true
    }

    let hideFoods = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.hide_foods) {
      hideFoods = true
    }

    let showExportPdf = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_export_pdf) {
      showExportPdf = user.configurations.food_plans.show_export_pdf
    }

    let updateFeedbackDay = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.update_feedback_day) {
      updateFeedbackDay = true
    }

    let autoAdjustOptions = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.auto_adjust_options) {
      autoAdjustOptions = user.configurations.food_plans.auto_adjust_options
    }

    let showDateEnd = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_date_end && this.client) {
      showDateEnd = user.configurations.food_plans.show_date_end
    }

    let showCaloricDeficit = false
    if (
      user &&
      user.configurations &&
      user.configurations.food_plans &&
      user.configurations.food_plans.show_caloric_deficit &&
      this.client
    ) {
      showCaloricDeficit = true
    }

    let showFoodParentCategoryFilter = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_food_parent_category_filter) {
      showFoodParentCategoryFilter = true
    }

    let showMacrosAverage = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_macros_average) {
      showMacrosAverage = true
    }

    let showDaysMacros = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_days_macros) {
      showDaysMacros = true
    }

    let hasAlternativesExtra = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_alternatives_extra) {
      hasAlternativesExtra = true
    }

    let showClientDetails = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_client_details) {
      showClientDetails = true
    }

    let showOptionsCount = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_options_count) {
      showOptionsCount = true
    }

    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: user,
      editPermission: editPermission,
      foodPlan: this.plan,
      dragging: false,
      day: null,
      dayIndex: null,
      meal: null,
      mealIndex: null,
      foodIndex: null,
      food: null,
      dialogFood: null,
      foodsItems: [],
      foodData: {},
      foodDataLoaded: false,
      newPlanParameter: this.$route.query?.new_plan,
      recipesDataLoaded: !showRecipes ? true : false,
      foodTypeUnit: {},
      showDetails: showDetails,
      showMealVideo: showMealVideo,
      showMealImage: showMealImage,
      showMealOption: showMealOption,
      showCategory: showCategory,
      categoryName: categoryName,
      showCategory2: showCategory2,
      category2Name: category2Name,
      hasValidation: hasValidation,
      hasFoodsExcludedCountriesValidation: hasFoodsExcludedCountriesValidation,
      hasFoodsIntolerancesValidation: hasFoodsIntolerancesValidation,
      hasAcceptedFoodsValidation: hasAcceptedFoodsValidation,
      hasRepeatedFoodsValidation: hasRepeatedFoodsValidation,
      hasRepeatedRecipesValidation: hasRepeatedRecipesValidation,
      hasMacrosValidation: hasMacrosValidation,
      hasAutoAdjustment: hasAutoAdjustment,
      showSaveAsHistory: showSaveAsHistory,
      hasAutoFillOption: hasAutoFillOption,
      warningSaveMessage: warningSaveMessage,
      preventRecipeChanges: preventRecipeChanges,
      warningBackMessage: warningBackMessage,
      showExitWarning: showExitWarning,
      openWithLink: openWithLink,
      showSaveOnlyInitialStep: showSaveOnlyInitialStep,
      showCopyPaste: showCopyPaste,
      showConvertToBase: showConvertToBase,
      showFoodsSortedByClient: showFoodsSortedByClient,
      showFoodsDisabledByClient: showFoodsDisabledByClient,
      showExcludeRecipeIcon: showExcludeRecipeIcon,
      showUpdateRecipeIcon: showUpdateRecipeIcon,
      hasAlternatives: hasAlternatives,
      hasFoodCategory: hasFoodCategory,
      hasFoodCategory2: hasFoodCategory2,
      showCopyFood: showCopyFood,
      showHeaderFixed: showHeaderFixed,
      showExcludeFoodIcon: showExcludeFoodIcon,
      sortRecipesByAcceptedFoods: sortRecipesByAcceptedFoods,
      hideAddMealButton: hideAddMealButton,
      hasTranslations: hasTranslations,
      hideMacros: hideMacros,
      hideFoods: hideFoods,
      showExportPdf: showExportPdf,
      updateFeedbackDay: updateFeedbackDay,
      autoAdjustOptions: autoAdjustOptions,
      showDateEnd: showDateEnd,
      showCaloricDeficit: showCaloricDeficit,
      showFoodParentCategoryFilter: showFoodParentCategoryFilter,
      showMacrosAverage: showMacrosAverage,
      showDaysMacros: showDaysMacros,
      hasAlternativesExtra: hasAlternativesExtra,
      showClientDetails: showClientDetails,
      showOptionsCount: showOptionsCount,
      imageMaxSize: 600,
      valuesItems: [],
      dialogGoalValues: false,
      goalValues: {},
      supplementType: 100,
      showSupplements: showSupplements,
      showDesserts: showDesserts,
      showSauces: showSauces,
      filterTypeEnabled: showDesserts || showSauces ? true : false,
      supplementsItems: [],
      dialogAddSupplement: false,
      supplementData: {},
      supplementToAdd: {},
      showFormulas: showFormulas,
      showFormulasFirstPage: showFormulasFirstPage,
      showUpdateMacros: showUpdateMacros,
      showRecipes: showRecipes,
      showRecipesPacks: showRecipesPacks,
      showOptionsAccordion: showOptionsAccordion,
      showOnlyPage: showOnlyPage,
      showPlanMacros: showPlanMacros,
      showAllMacrosInMeal: showAllMacrosInMeal,
      showMealOptionsMacros: showMealOptionsMacros,
      optionsAccordionActive: [],
      mealsOptions: null,
      mealOptionTab: 0,
      mealOptionMacros: {},
      planMacros: {},
      dialogRecipe: false,
      recipesItems: [],
      recipesItemsDict: {},
      recipesItemsNamesDict: {},
      recipeSelected: null,
      recipeParentCategoryFilter: null,
      recipeParentCategories: Utils.getRecipeParentCategories(),
      recipeCategoryFilter: null,
      recipeCategories: Utils.getRecipeCategories(),
      recipeCategoriesDict: this.getRecipeCategoriesDict(),
      dialogRecipesPack: false,
      recipesPackSelected: null,
      recipesPacksItems: [],
      recipesPacksType: 101,
      nutritionCategories: Utils.getNutritionCategories(),
      nutritionCategories2: Utils.getNutritionCategories2(),
      showUserNotes: showUserNotes,
      showWaterCalculation: showWaterCalculation,
      showOverview: showOverview,
      daysOverviewOpened: [],
      daysOverviewData: {},
      waterCalculationValue: null,
      errorsFoodsIds: [],
      errorsSupplementsIds: [],
      errorsRecipes: [],
      errorsDaysMacros: {},
      dialogWarningBackMessage: false,
      foodCategoriesDict: this.getFoodCategoriesDict(),
      clipboardPlan: window.clipboardFoodPlan,
      changes: [],
      typesOptionsTabs: [],
      typeOptionTab: 0,
      dialogExcludeRecipe: false,
      excludeRecipeData: null,
      updateMealRecipe: false,
      recipeIndexToUpdate: null,
      cloneFoodCopied: window.foodPlanFoodClone,
      headerFixedActive: false,
      headerFixedWidth: null,
      dialogAutoMealsAdjustment: false,
      autoMealsAdjustmentData: null,
      foodVegetarianItems: Utils.getFoodVegetarian(),
      foodIntoleranceItems: Utils.getFoodIntolerance(),
      feedbackRef: null,
      formulasRef: null,
      recipeInitialData: null,
      openNotesValuesCallback: autoAdjustOptions
        ? {
            include_recipes: this.autoAddMeal,
          }
        : null,
      dialogSelectDay: false,
      dialogSelectDayCallback: null,
      cloneDayCopied: window.foodPlanDayClone,
      endDateDatepicker: null,
      foodCategoriesList: this.getFoodCategoriesList(),
      foodParentCategoriesList: Utils.getFoodParentCategories(),
      foodParentCategoryFilter: null,
      foodCategoryFilter: null,
      caloricDeficit: null,
      normalizeName: Utils.normalizeName,
    }
  },
  computed: {
    recipesItemsFiltered() {
      const self = this
      if (this.dialogRecipe) {
        let recipes = JSON.parse(JSON.stringify(this.recipesItems))
        if (this.recipeParentCategoryFilter) {
          recipes = recipes.filter(function (item) {
            return self.recipeParentCategoryFilter === item.parent_category
          })
        }
        if (this.recipeCategoryFilter) {
          recipes = recipes.filter(function (item) {
            return self.recipeCategoryFilter === item.category
          })
        }
        if (this.hasRepeatedRecipesValidation) {
          const recipesAdded = []
          this.foodPlan.days.forEach(function (day) {
            day.meals.forEach(function (meal) {
              recipesAdded.push(Utils.normalizeName(meal.name))
            })
          })
          if (recipesAdded.length) {
            recipes = recipes.map((item) => {
              item.disabled = item.name && recipesAdded.indexOf(Utils.normalizeName(item.name)) > -1
              return item
            })
          }
        }
        const notes = this.getClientNotes()
        const recipesExcludedIds =
          notes && notes.exclude_recipes && notes.exclude_recipes.length
            ? notes.exclude_recipes.map(function (rec) {
                return rec.id
              })
            : []
        if (recipesExcludedIds.length) {
          recipes = recipes.map((item) => {
            if (!item.disabled) {
              item.disabled = item.id && recipesExcludedIds.indexOf(item.id) > -1
            }
            return item
          })
        }
        if (this.sortRecipesByAcceptedFoods) {
          const includeRecipes = notes && notes.include_recipes ? notes.include_recipes : []
          const includeFoods = notes && notes.accepted_foods ? notes.accepted_foods : []
          const recipesCount = {}
          if (includeFoods.length) {
            recipes.forEach(function (r) {
              let count = 0
              r.foods.forEach(function (f) {
                if (includeFoods.indexOf(f.food_id) > -1) {
                  count++
                }
              })
              recipesCount[r.id] = count
            })
          }
          recipes.sort((recipeA, recipeB) => {
            const inIncludeRecipesA = includeRecipes.includes(recipeA.id)
            const inIncludeRecipesB = includeRecipes.includes(recipeB.id)
            if (inIncludeRecipesA && !inIncludeRecipesB) {
              return -1
            }
            if (!inIncludeRecipesA && inIncludeRecipesB) {
              return 1
            }
            const countA = recipesCount[recipeA.id] ? recipesCount[recipeA.id] : 0
            const countB = recipesCount[recipeB.id] ? recipesCount[recipeB.id] : 0
            return countB - countA
          })
        }
        return recipes
      }
      return []
    },
    foodsItemsFiltered() {
      const self = this
      let items = JSON.parse(JSON.stringify(this.foodsItems))
      if (this.showFoodParentCategoryFilter && this.foodParentCategoryFilter) {
        items = items.filter(function (item) {
          return self.foodParentCategoryFilter === item.parent_category
        })
      }
      if (this.hasFoodCategory && this.foodCategoryFilter && this.foodCategoryFilter.length) {
        items = items.filter(function (item) {
          return (
            self.foodCategoryFilter.indexOf(item.category) > -1 ||
            (self.hasFoodCategory2 && self.foodCategoryFilter.indexOf(item.category2) > -1)
          )
        })
      }
      if (!this.client || (!this.showFoodsSortedByClient && !this.showFoodsDisabledByClient)) {
        return items
      }
      const notes = this.getClientNotes()
      const includeFoods = notes && notes.accepted_foods ? notes.accepted_foods : []
      const foodIntolerance = notes && notes.food_intolerance ? notes.food_intolerance : []
      const foodVegetarian = notes && notes.food_vegetarian ? notes.food_vegetarian : null
      let foodVegetarianIntolerance = null
      const intolerantFoods = notes && notes.intolerant_foods ? notes.intolerant_foods : []
      const unacceptedFoods = notes && notes.unaccepted_foods ? notes.unaccepted_foods : []
      const excludeFoodGroups = notes && notes.exclude_food_groups ? notes.exclude_food_groups : []
      const excludeFoods = new Set([...intolerantFoods, ...unacceptedFoods])
      if (excludeFoodGroups && excludeFoodGroups.length) {
        const excludeFoodGroupsItems = items.filter(function (e) {
          return excludeFoodGroups.indexOf(e.food_group) > -1 || excludeFoodGroups.indexOf(e.food_group2) > -1
        })
        excludeFoodGroupsItems.forEach(function (e) {
          if (!excludeFoods.has(e.id)) {
            excludeFoods.add(e.id)
          }
        })
      }
      if (this.hasRepeatedFoodsValidation && this.meal && this.meal.foods) {
        this.meal.foods.forEach(function (f) {
          if (!excludeFoods.has(f.food_id)) {
            excludeFoods.add(f.food_id)
          }
        })
      }
      if (this.hasFoodsIntolerancesValidation && this.foodVegetarianItems && this.foodIntoleranceItems && foodVegetarian) {
        const foodVegItem = this.foodVegetarianItems.find(function (v) {
          return v.value === foodVegetarian
        })
        if (foodVegItem && foodVegItem.nutrition_category) {
          const foodIntItem = this.foodIntoleranceItems.find(function (t) {
            return t.nutrition_category === foodVegItem.nutrition_category
          })
          if (foodIntItem) {
            foodVegetarianIntolerance = foodIntItem.value
          }
        }
      }
      items = items.map((item) => {
        if (self.showFoodsDisabledByClient) {
          item.disabled = excludeFoods.has(item.id)
        }
        if (self.hasFoodsIntolerancesValidation && foodIntolerance && foodIntolerance.length) {
          try {
            const intolerances = JSON.parse(item.intolerances)
            if (intolerances.indexOf(foodIntolerance) > -1 || intolerances.indexOf(foodVegetarianIntolerance) > -1) {
              item.disabled = true
            }
          } catch {}
        }
        if (self.showFoodsSortedByClient) {
          item.included = includeFoods.indexOf(item.id) > -1
        }
        return item
      })
      if (this.showFoodsSortedByClient) {
        items.sort((a, b) => {
          const aIncluded = a.included
          const bIncluded = b.included
          if (aIncluded === bIncluded) {
            return Utils.normalizeName(a.name).localeCompare(Utils.normalizeName(b.name))
          } else if (aIncluded) {
            return -1
          } else {
            return 1
          }
        })
        let lastIncluded = null
        for (let i = 0; i < items.length; i++) {
          if (items[i].included) {
            lastIncluded = items[i]
          }
          if (!items[i].included) {
            break
          }
        }
        if (lastIncluded) {
          lastIncluded.separator = true
        }
      }
      return items
    },
    planFoods() {
      const foods = []
      try {
        this.foodPlan.days.forEach(function (day) {
          day.meals.forEach(function (meal) {
            meal.foods.forEach(function (food) {
              foods.push(food.food_id)
            })
          })
        })
      } catch {}
      return foods
    },
    planRecipes() {
      const recipes = []
      try {
        this.foodPlan.days.forEach(function (day) {
          day.meals.forEach(function (meal) {
            if (meal.name) {
              recipes.push(Utils.normalizeName(meal.name))
            }
          })
        })
      } catch {}
      return recipes
    },
  },
  watch: {
    recipesDataLoaded(newVal) {
      if (newVal) {
        this.mounted()
      }
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getFoodFields()
    this.getFoodsItems()
    this.getFoodTypesUnits()
    if (this.filterTypeEnabled) {
      this.getTypesOptionsTabs()
    }
    if (this.showSupplements) {
      this.getSupplements()
    }
    if (this.showRecipes) {
      this.getRecipes()
    }
    if (this.showRecipesPacks) {
      this.getRecipesPacks()
    }
    if (this.showPlanMacros) {
      this.getPlanMacros()
    }
    if (this.showWaterCalculation && this.client) {
      this.setWaterCalculation()
    }
    this.planValidation()
    this.activeEventListener()
    if (this.showHeaderFixed && this.headerFixed) {
      this.activeHeaderFixed()
    }
    if (this.baseUsed) {
      this.calculateFoodPlan()
    }
    this.setCaloricDeficit()
  },
  beforeDestroy: function () {
    this.removeEventListener()
  },
  methods: {
    back: function (saved) {
      if (this.warningBackMessage && this.editPermission && this.meal) {
        return (this.dialogWarningBackMessage = true)
      }
      if (this.checkPreventRecipeChanges()) {
        this.confirmBack(saved)
      }
    },
    checkPreventRecipeChanges: function () {
      const self = this
      if (this.preventRecipeChanges && this.meal && this.meal.foods && this.recipeInitialData && this.recipeInitialData.foods) {
        let initialFoods = ''
        let currentFoods = ''
        this.recipeInitialData.foods.forEach(function (item) {
          initialFoods += item.food_id + '-' + (self.foodData[item.food_id] ? self.foodData[item.food_id].name : '') + ' |'
        })
        this.meal.foods.forEach(function (item) {
          currentFoods += item.food_id + '-' + (self.foodData[item.food_id] ? self.foodData[item.food_id].name : '') + ' |'
        })
        if (
          initialFoods !== currentFoods &&
          this.recipeInitialData.name === this.meal.name &&
          this.recipeInitialData.description === this.meal.description
        ) {
          this.$alert(this.preventRecipeChanges, '', 'warning', Utils.getAlertOptions())
          return false
        }
      }
      return true
    },
    confirmBack: function (saved) {
      if (this.meal) {
        if (this.meal.new) {
          if (!this.meal.name && !this.meal.foods.length) {
            this.day.meals.splice(this.day.meals.length - 1, 1)
          } else {
            delete this.meal.new
          }
        }
        this.meal = null
        this.dialogWarningBackMessage = false
        this.getMealsOptions()

        if (!this.showOverview || (this.showOverview && !this.dayOverviewMealOpened)) {
          return true
        }
      }
      if (this.day) {
        if (this.day.new) {
          if (!this.day.name && !this.day.meals.length) {
            this.foodPlan.days.splice(this.foodPlan.days.length - 1, 1)
          } else {
            delete this.day.new
          }
        }
        this.day = null

        if (!this.showOverview || (this.showOverview && !this.dayOverviewMealOpened)) {
          this.resetDaysOverviews()
        }

        this.dayOverviewMealOpened = false
        return true
      }
      this.saved = saved
      if (this.hasBack) {
        this.$router.goBack()
      }
    },
    getFoodCategoriesDict: function () {
      const obj = {}
      const items = Utils.getFoodCategories()
      if (items && items.length) {
        for (let i = 0; i < items.length; i++) {
          obj[items[i].value] = items[i]
        }
      }
      return obj
    },
    getRecipeCategoriesDict: function () {
      const obj = {}
      const items = Utils.getRecipeCategories()
      if (items && items.length) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].id) {
            obj[items[i].id] = items[i]
          }
        }
      }
      return obj
    },
    getFoodCategoriesList: function () {
      const items = Utils.getFoodCategories()
      if (items && items.length) {
        return items.filter((c) => c.label)
      }
      return []
    },
    getFoodFields: function () {
      const fields = [
        {
          title: window.strings['calories_kcal'],
          currentKey: 'calories',
          goalKey: 'calories_goal',
        },
        {
          title: window.strings['protein_g'],
          currentKey: 'protein',
          goalKey: 'protein_goal',
          extraKey: this.user?.configurations?.food_plans?.formulas?.show_percentages ? 'total_kcal_protein' : null,
        },
        {
          title: window.strings['carbohydrates_g'],
          currentKey: 'carbs',
          goalKey: 'carbs_goal',
          extraKey: this.user?.configurations?.food_plans?.formulas?.show_percentages ? 'total_kcal_carbs' : null,
        },
        {
          title: window.strings['fat_g'],
          currentKey: 'fat',
          goalKey: 'fat_goal',
          extraKey: this.user?.configurations?.food_plans?.formulas?.show_percentages ? 'total_kcal_fat' : null,
        },
      ]

      if (
        this.user &&
        this.user.configurations &&
        this.user.configurations.food_plans &&
        this.user.configurations.food_plans.show_sub_macros
      ) {
        fields.push({
          title: window.strings['fiber_g'],
          currentKey: 'fiber',
          goalKey: 'fiber_goal',
        })
        fields.push({
          title: window.strings['sodium_g'],
          currentKey: 'sodium',
          goalKey: 'sodium_goal',
        })
        fields.push({
          title: window.strings['sugar_g'],
          currentKey: 'sugar',
          goalKey: 'sugar_goal',
        })
      }

      this.valuesItems = fields
    },
    getMacroExtraValue: function (key) {
      try {
        const values = this.formulasRef.getFormulasUserValues()
        if (values[key]) {
          return values[key] + '%'
        }
      } catch {}
      return ''
    },
    getFoodsItems: function () {
      const self = this
      Api.getFoods(
        {
          useCache: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.foodsItems = response.data
            self.foodData = {}
            for (let i = 0; i < response.data.length; i++) {
              self.foodData[response.data[i].id] = response.data[i]
            }
            self.foodDataLoaded = true
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getFoodItem: function (id) {
      for (let i = 0; i < this.foodsItems.length; i++) {
        if (this.foodsItems[i].id === id) {
          return this.foodsItems[i]
        }
      }
      return {}
    },
    getFoodTypesUnits: function () {
      const foodTypes = Utils.getFoodTypes()
      this.foodTypeUnit = {}
      if (foodTypes && foodTypes.length) {
        for (let i = 0; i < foodTypes.length; i++) {
          this.foodTypeUnit[foodTypes[i].value] = foodTypes[i]
        }
      }
    },
    getTypesOptionsTabs: function () {
      const items = Utils.getRecipeCategories()
      let types = []
      if (items && items.length) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].app) {
            types.push({
              order: items[i].app.order,
              values: [items[i].app.value],
              label: items[i].app.label,
            })
          }
        }
        types = types.sort((a, b) => a.order - b.order)
      }
      this.typesOptionsTabs = types
    },
    getSupplements: function () {
      const self = this
      Api.getContents(
        {
          type: this.supplementType,
          useCache: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.supplementsItems = response.data
            self.supplementData = {}
            for (let i = 0; i < response.data.length; i++) {
              self.supplementData[response.data[i].id] = response.data[i]
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getRecipes: function () {
      const self = this
      Api.getRecipes(
        {
          useCache: true,
        },
        function (response) {
          if (response.success) {
            const recipes = {}
            const recipesNames = {}
            response.data.forEach(function (item) {
              item.name = Utils.normalizeName(item.name)
              recipes[item.id] = item
              recipesNames[Utils.normalizeName(item.name)] = item
            })
            self.recipesItemsDict = recipes
            self.recipesItemsNamesDict = recipesNames
            self.recipesItems = response.data
            self.recipesDataLoaded = true
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getRecipesPacks: function () {
      const self = this
      Api.getContents(
        {
          type: this.recipesPacksType,
          useCache: true,
        },
        function (response) {
          if (response.success) {
            self.recipesPacksItems = response.data
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getPlanMacros: function () {
      const planMacros = {}
      let mealTypeDone = {}
      for (let d = 0; d < this.foodPlan.days.length; d++) {
        mealTypeDone = {}
        const day = this.foodPlan.days[d]
        if (this.showMacrosAverage) {
          this.valuesItems.forEach((item) => {
            const dayMacrosAverage = this.getDayMacrosAverage(day, item.currentKey)
            if (!planMacros[item.currentKey]) {
              planMacros[item.currentKey] = 0
            }
            planMacros[item.currentKey] += dayMacrosAverage
          })
        } else {
          day.meals.forEach((meal) => {
            if (meal && !mealTypeDone[meal.type]) {
              mealTypeDone[meal.type] = true
              this.valuesItems.forEach((item) => {
                if (!planMacros[item.currentKey]) {
                  planMacros[item.currentKey] = 0
                }
                planMacros[item.currentKey] += meal[item.currentKey]
              })
            }
          })
        }
      }
      if (!this.initialCaloriesValue) {
        this.initialCaloriesValue = planMacros.calories
      }
      this.planMacros = planMacros
    },
    getDayMacrosAverage: function (day, key) {
      if (day && day.meals) {
        const total = day.meals.reduce((sum, meal) => sum + meal[key], 0)
        if (isNaN(total) || total === 0) {
          return 0
        }
        return Math.round((total / day.meals.length) * 100) / 100
      }
      return '-'
    },
    setWaterCalculation: function () {
      const self = this
      this.waterCalculationValue = '...'
      Api.getLastPhysicalEvaluation(
        {
          id: this.client.dbId,
          useCache: true,
        },
        function (response) {
          if (response.success) {
            const weight = response.data[0] && response.data[0].body ? response.data[0].body.weight : null
            if (weight) {
              self.waterCalculationValue = Math.round(weight * 0.035 * 10) / 10 + 'l'
            } else {
              self.waterCalculationValue = window.strings['n_a']
            }
          }
        },
      )
    },
    newDay: function () {
      this.foodPlan.days.push({
        id: this.getNewDayId(),
        name: 'Dia 1',
        description: '',
        calories_goal: '',
        protein_goal: '',
        carbs_goal: '',
        fat_goal: '',
        fiber_goal: '',
        sodium_goal: '',
        sugar_goal: '',
        meals: [],
        new: true,
      })
      const index = this.foodPlan.days.length - 1
      this.dayIndex = index
      this.day = this.foodPlan.days[index]
      this.getMealsOptions()
      this.changes.push(this.changesKeys().dayAdded(null))
      this.saved = false
    },
    getNewDayId: function () {
      let newId = 0
      this.foodPlan.days.forEach(function (day) {
        if (day.id && day.id > newId) {
          newId = day.id
        }
      })
      return newId + 1
    },
    openDay: function (index) {
      this.dayIndex = index
      this.day = this.foodPlan.days[index]
      this.getMealsOptions()
    },
    getMealsOptions: function (refresh) {
      if (!refresh) {
        this.optionsAccordionActive = []
      }
      const options = []
      const allOptions = []
      this.day.meals.forEach(function (ml) {
        if (allOptions.indexOf(ml.option) === -1) {
          allOptions.push(ml.option)
        }
      })
      for (let m = 0; m < this.day.meals.length; m++) {
        const meal = this.day.meals[m]
        if ((meal.option || allOptions.length > 1) && options.indexOf(meal.option) === -1) {
          options.push(meal.option)
          if (!refresh && this.showOptionsAccordion) {
            this.optionsAccordionActive.push(options.length - 1)
          }
        }
      }
      this.mealsOptions = options
      if (!refresh && options.length) {
        this.selectMealOption()
      }
    },
    selectMealOption: function () {
      const self = this
      setTimeout(function () {
        const dayClone = JSON.parse(JSON.stringify(self.day))
        const typesFiltered =
          self.filterTypeEnabled && self.typesOptionsTabs[self.typeOptionTab] && self.typesOptionsTabs[self.typeOptionTab].values
            ? self.typesOptionsTabs[self.typeOptionTab].values
            : null
        self.mealOptionMacros = {}

        if (!dayClone) {
          return false
        }

        for (let m = dayClone.meals.length - 1; m > -1; m--) {
          let filtered = false
          if (self.showOptionsAccordion) {
            for (let f = 0; f < self.optionsAccordionActive.length; f++) {
              if (dayClone.meals[m].option === self.mealsOptions[self.optionsAccordionActive[f]]) {
                filtered = true
                break
              }
            }
          } else {
            filtered = dayClone.meals[m].option === self.mealsOptions[self.mealOptionTab] ? true : false
          }
          if (typesFiltered && typesFiltered.indexOf(dayClone.meals[m].type) === -1) {
            filtered = false
          }
          if (!filtered) {
            dayClone.meals.splice(m, 1)
          }
        }

        Api.calculateFoodPlan(
          {
            days: [dayClone],
          },
          function (response) {
            if (response.success) {
              const day = response.data.days[0]
              if (day) {
                for (let i = 0; i < self.valuesItems.length; i++) {
                  const element = self.valuesItems[i]
                  self.mealOptionMacros[element.currentKey] = day[element.currentKey]
                  self.$forceUpdate()
                }
              }
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      })
    },
    selectMealType: function () {
      if (this.mealsOptions) {
        const optsIndexs = []
        this.mealsOptions.forEach(function (opt, index) {
          optsIndexs.push(index)
        })
        this.optionsAccordionActive = optsIndexs
      }
      this.selectMealOption()
    },
    copyDay: function (index) {
      const clone = JSON.parse(JSON.stringify(this.foodPlan.days[index]))
      delete clone.id
      window.foodPlanDayClone = clone
      this.cloneDayCopied = clone
      this.$notify({
        group: 'alert',
        text: window.strings['data_copied'],
        duration: 2000,
      })
    },
    addCloneDayCopied: function () {
      this.cloneDayCopied.id = this.getNewDayId()
      this.foodPlan.days.push(this.cloneDayCopied)
      this.changes.push(this.changesKeys().dayDuplicated(this.cloneDayCopied.name))
      this.saved = false
      this.cloneDayCopied = null
    },
    duplicateDay: function (index) {
      const clone = JSON.parse(JSON.stringify(this.foodPlan.days[index]))
      clone.id = this.getNewDayId()
      this.foodPlan.days.push(clone)
      this.changes.push(this.changesKeys().dayDuplicated(clone.name))
      this.saved = false
      this.calculateFoodPlan()
    },
    openDeleteDay: function (index) {
      const self = this
      const day = this.foodPlan.days[index]

      this.$confirm(
        window.strings['want_delete_day'] + (day ? ' "' + Utils.normalizeName(day.name) + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.changes.push(self.changesKeys().dayDeleted(self.foodPlan.days[index].name))
          this.saved = false
          self.foodPlan.days.splice(index, 1)
          self.day = null
          self.resetDaysOverviews()
          self.calculateFoodPlan()
        })
        .catch(() => {})
    },
    newMeal: function (source, option) {
      const obj = {
        name: source && source.name ? source.name : window.strings['meal'] + ' 1',
        option: this.hasAutoFillOption ? this.getNewOption(this.filterTypeEnabled ? source : null) : option ? option : '',
        description: source && source.description ? source.description : '',
        video_url: '',
        image:
          source && source.image
            ? source.image
            : {
                id: null,
                image: null,
              },
        foods: source && source.foods ? source.foods : [],
        type: source && source.type ? source.type : 0,
        category: source && source.category ? source.category : '',
        new: true,
      }

      if (this.showSupplements && source && source.extra_data) {
        obj.extra_data = source.extra_data
      }

      this.day.meals.push(obj)
      const index = this.day.meals.length - 1
      this.mealIndex = index
      this.meal = this.day.meals[index]
      if (source) {
        if (this.hasAutoAdjustment) {
          this.applyAutoMealAdjustment()
        } else {
          this.calculateFoodPlan()
        }
      }
      if (this.showMealOptionsMacros) {
        this.showDayOverview(this.day, true)
      }
      this.mealOpened()
    },
    getNewOption: function (source) {
      if (this.day && this.day.meals) {
        let option = 0
        let optionStr = ''
        let optionStrDone = false
        this.day.meals.forEach(function (item) {
          if (!source || (source && source.type === item.type)) {
            const itemOption = item.option ? parseInt(item.option.replace(/\D/g, '')) : 0
            option = !isNaN(itemOption) && itemOption > option ? itemOption : option
          }
          if (!optionStr && item.option && !item.type) {
            optionStr = item.option.replace(/[0-9]/g, '').replace('  ', ' ')
            optionStrDone = true
          }
        })
        option += 1
        if (!optionStrDone) {
          optionStr = 'OPÇÃO '
        }
        return optionStr + option.toString()
      }
      return ''
    },
    newMeals: function (data) {
      const self = this
      data.forEach(function (source) {
        const obj = {
          name: source && source.name ? source.name : window.strings['meal'] + ' 1',
          option: source.option ? source.option : '',
          description: source && source.description ? source.description : '',
          video_url: '',
          image:
            source && source.image
              ? source.image
              : {
                  id: null,
                  image: null,
                },
          foods: source && source.foods ? source.foods : [],
          type: source && source.type ? source.type : 0,
          category: source && source.category ? source.category : '',
        }
        if (self.showSupplements && source && source.extra_data) {
          obj.extra_data = source.extra_data
        }
        self.day.meals.push(obj)
      })
      this.calculateFoodPlan()
    },
    updateMeal: function (source) {
      const obj = {
        name: source && source.name ? source.name : window.strings['meal'] + ' 1',
        description: source && source.description ? source.description : '',
        foods: source && source.foods ? source.foods : [],
        image: source && source.image ? source.image : null,
        type: source && source.type ? source.type : 0,
        category: source && source.category ? source.category : '',
      }
      if (this.showSupplements && source && source.extra_data) {
        obj.extra_data = source.extra_data
      }
      if (this.meal || this.mealIndex || this.mealIndex === 0) {
        for (const key in obj) {
          this.day.meals[this.mealIndex][key] = obj[key]
        }
        this.meal = this.day.meals[this.mealIndex]
      } else {
        if (this.recipeIndexToUpdate || this.recipeIndexToUpdate === 0) {
          for (const key in obj) {
            this.day.meals[this.recipeIndexToUpdate][key] = obj[key]
          }
        }
      }
      if (this.hasAutoAdjustment) {
        this.applyAutoMealAdjustment()
      } else {
        this.calculateFoodPlan()
      }
      if (this.showMealOptionsMacros && this.day) {
        this.showDayOverview(this.day, true)
      }
      this.mealOpened()
    },
    applyAutoMealAdjustment: function () {
      const self = this
      const defaultOption =
        this.day && this.day.meals
          ? this.day.meals.find(function (m) {
              return m.type === 0
            })
          : null
      if (defaultOption && this.meal && this.meal.foods) {
        const caloriesDiff = this.hasMacrosValidation
          ? this.hasMacrosValidation.find(function (c) {
              return c.id === 'calories'
            })
          : null
        const proteinDiff = this.hasMacrosValidation
          ? this.hasMacrosValidation.find(function (c) {
              return c.id === 'protein'
            })
          : null
        const foodFields = ['food_id', 'type', 'value', 'calories', 'protein', 'carbs', 'fat']
        let minCalories = defaultOption.calories
        let maxCalories = defaultOption.calories
        let minProtein = defaultOption.protein
        let maxProtein = defaultOption.protein
        this.day.meals.forEach(function (m) {
          if (m.type === 0) {
            minCalories = minCalories > m.calories ? m.calories : minCalories
            maxCalories = maxCalories < m.calories ? m.calories : maxCalories
            minProtein = minProtein > m.protein ? m.protein : minProtein
            maxProtein = maxProtein < m.protein ? m.protein : maxProtein
          }
        })
        const obj = {
          calories_min: minCalories,
          calories_max: maxCalories,
          calories_diff: caloriesDiff.value,
          protein_min: minProtein,
          protein_max: maxProtein,
          protein_diff: proteinDiff.value,
          foods: [],
        }
        this.meal.foods.forEach(function (food) {
          const foodObj = {}
          foodFields.forEach(function (key) {
            foodObj[key] = food[key]
          })
          obj.foods.push(foodObj)
        })
        obj.foods = obj.foods.sort((a, b) => b.type - a.type)
        this.$isLoading(true)
        Api.autoMealAdjustment(obj, function (response) {
          if (!response.success && response.message) {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
          if (response.data && response.data.foods) {
            for (let f = 0; f < self.day.meals[self.mealIndex].foods.length; f++) {
              const foodSrc = response.data.foods.find(function (s) {
                return s.food_id === self.day.meals[self.mealIndex].foods[f].food_id
              })
              if (foodSrc) {
                self.day.meals[self.mealIndex].foods[f].value = foodSrc.value
              }
            }
            self.meal = self.day.meals[self.mealIndex]
            self.$set(self.day.meals, self.mealIndex, self.day.meals[self.mealIndex])
            self.$forceUpdate()
            self.mealOpened()
          }
          self.calculateFoodPlan()
        })
      } else {
        this.calculateFoodPlan()
      }
    },
    autoAddMeal: function (item) {
      const self = this
      if (this.autoAdjustOptions && this.autoAdjustOptions.max_meals) {
        const newMeal = this.recipesItemsDict[item.id] ? JSON.parse(JSON.stringify(this.recipesItemsDict[item.id])) : null
        if (!newMeal) {
          return this.$alert(window.strings['recipe_not_found'], '', 'warning', Utils.getAlertOptions())
        }
        newMeal.type = 0
        if (this.day && !this.meal) {
          if (this.day.meals.length < this.autoAdjustOptions.max_meals) {
            this.newMeal(newMeal)
            this.changes.push(this.changesKeys().recipeAdded(newMeal.name))
            this.saved = false
          } else {
            this.$alert(
              this.autoAdjustOptions.error_message
                .replace('<meal_name>', Utils.normalizeName(newMeal.name))
                .replace('<day_name>', Utils.normalizeName(this.day.name)),
              '',
              'warning',
              Utils.getAlertOptions(),
            )
          }
          return true
        }
        if (this.day && this.meal) {
          newMeal.type = this.meal.type
          this.changes.push(this.changesKeys().recipeUpdated(newMeal.name))
          this.updateMeal(newMeal)
          this.saved = false
          return true
        }
        if (!this.day) {
          this.dialogSelectDayCallback = function (day, dayIndex) {
            self.closeDialogSelectDay()
            if (day.meals.length < self.autoAdjustOptions.max_meals) {
              self.openDay(dayIndex)
              self.newMeal(newMeal)
              self.changes.push(self.changesKeys().recipeAdded(newMeal.name))
              self.saved = false
            } else {
              self.$alert(
                self.autoAdjustOptions.error_message
                  .replace('<meal_name>', Utils.normalizeName(newMeal.name))
                  .replace('<day_name>', Utils.normalizeName(day.name)),
                '',
                'warning',
                Utils.getAlertOptions(),
              )
            }
          }
          this.dialogSelectDay = true
          return true
        }
      }
    },
    closeDialogSelectDay: function () {
      this.dialogSelectDay = false
      this.dialogSelectDayCallback = null
    },
    autoAddIncludeRecipe: function () {
      try {
        const notes = this.getClientNotes()
        let includeRecipeIds = notes?.include_recipes ? [...notes.include_recipes] : []
        if (!includeRecipeIds.length) return

        const existingRecipeIds = this.foodPlan.days
          .flatMap((day) => day.meals.map((meal) => this.recipesItemsNamesDict[Utils.normalizeName(meal.name)]?.id))
          .filter(Boolean)
        includeRecipeIds = includeRecipeIds.filter((recipeId) => !existingRecipeIds.includes(recipeId))

        for (let i = 0; i < this.foodPlan.days.length; i++) {
          const day = this.foodPlan.days[i]
          if (day.meals.length >= this.autoAdjustOptions.max_meals) continue

          const dayRecipes = day.meals
            .map((meal) => this.recipesItemsNamesDict[Utils.normalizeName(meal.name)])
            .filter((recipe) => recipe !== undefined)
          const recipeCategory = day.meals[0]?.category || dayRecipes[0]?.category
          if (!recipeCategory) continue

          const recipeIdToInclude = includeRecipeIds.find((recipeId) => this.recipesItemsDict[recipeId]?.category === recipeCategory)
          if (!recipeIdToInclude) continue

          const newMeal = this.recipesItemsDict[recipeIdToInclude]
          if (newMeal) {
            this.openDay(i)
            this.$alert(
              Utils.normalizeName(newMeal.name) +
                ' ' +
                window.strings['added'].toLowerCase() +
                ' ' +
                window.strings['in_the_meal'] +
                ' ' +
                Utils.normalizeName(day.name) +
                '. ' +
                window.strings['validate_change_save_plan'],
              '',
              'warning',
              Utils.getAlertOptions(),
            ).then(() => {
              this.newMeal({ ...newMeal })
              this.changes.push(this.changesKeys().recipeAdded(newMeal.name))
              this.saved = false
            })
            return true
          }
        }
      } catch {}
      return false
    },
    updateRecipe: function (mealIndex) {
      this.newRecipe(true, null, mealIndex)
    },
    newRecipe: function (update, categoryFilter, mealIndex) {
      const notes = this.getClientNotes()
      const recipesExcludedIds =
        notes && notes.exclude_recipes && notes.exclude_recipes.length
          ? notes.exclude_recipes.map(function (rec) {
              return rec.id
            })
          : []
      for (let i = 0; i < this.recipesItems.length; i++) {
        if (recipesExcludedIds.indexOf(this.recipesItems[i].id) > -1) {
          this.recipesItems[i].disabled = true
        } else {
          this.recipesItems[i].disabled = false
        }
      }
      this.updateMealRecipe = update ? true : false
      this.recipeIndexToUpdate = update ? mealIndex : null
      this.recipeSelected = null
      this.recipeParentCategoryFilter = null
      this.recipeCategoryFilter = categoryFilter ? categoryFilter : null
      this.newRecipeFilter = categoryFilter ? parseInt(categoryFilter) : 0
      this.dialogRecipe = true
    },
    cancelRecipe: function () {
      this.dialogRecipe = false
      this.recipeSelected = null
    },
    saveRecipe: function () {
      const self = this
      this.dialogRecipe = false
      if (this.recipeSelected) {
        const recipe = this.recipesItems.find(function (item) {
          return self.recipeSelected === item.id
        })
        if (!this.showMealImage) {
          delete recipe.image
        }
        if (this.updateMealRecipe) {
          if (this.meal) {
            recipe.type = this.meal.type
          } else {
            recipe.type = this.newRecipeFilter
          }
          this.changes.push(this.changesKeys().recipeUpdated(recipe.name))
          this.updateMeal(recipe)
          this.saved = false
        } else {
          recipe.type = this.newRecipeFilter
          this.newMeal(recipe)
          this.changes.push(this.changesKeys().recipeAdded(recipe.name))
          this.saved = false
        }
      }
      this.recipeSelected = null
    },
    newRecipesPack: function () {
      const notes = this.getClientNotes()
      const recipesExcludedIds =
        notes && notes.exclude_recipes && notes.exclude_recipes.length
          ? notes.exclude_recipes.map(function (rec) {
              return rec.id
            })
          : []
      for (let i = 0; i < this.recipesPacksItems.length; i++) {
        const recipesIds = this.recipesPacksItems[i].description ? JSON.parse(this.recipesPacksItems[i].description) : []
        if (recipesExcludedIds.some((r) => recipesIds.includes(r))) {
          this.recipesPacksItems[i].disabled = true
        } else {
          this.recipesPacksItems[i].disabled = false
        }
      }
      this.recipesPackSelected = null
      this.dialogRecipesPack = true
    },
    cancelNewRecipesPack: function () {
      this.dialogRecipesPack = false
      this.recipesPackSelected = null
    },
    saveNewRecipesPack: function () {
      const self = this
      try {
        const pack = this.recipesPacksItems.find(function (item) {
          return item.id === self.recipesPackSelected
        })
        if (pack) {
          const recipesIds = pack.description ? JSON.parse(pack.description) : []
          const recipes = this.recipesItems.filter(function (item) {
            return recipesIds.indexOf(item.id) > -1
          })
          let option = 0
          let optionStr = ''
          this.day.meals.forEach(function (item) {
            const itemOption = item.option ? parseInt(item.option.replace(/\D/g, '')) : 0
            option = !isNaN(itemOption) && itemOption > option ? itemOption : option
            if (!optionStr && item.option) {
              optionStr = item.option.replace(/[0-9]/g, '').replace('  ', ' ')
            }
          })
          recipes.forEach(function (item) {
            option += 1
            item.option = optionStr + option.toString()
          })
          self.newMeals(recipes)
          self.changes.push(self.changesKeys().recipesPackAdded(pack.name))
          this.saved = false
          self.getMealsOptions()
        }
      } catch {}
      this.cancelNewRecipesPack()
    },
    openMeal: function (index) {
      if (this.showMealOptionsMacros) {
        this.showDayOverview(this.day, true)
      }
      this.mealIndex = index
      this.meal = this.day.meals[index]
      this.refreshMealImage()
      this.mealOpened()
    },
    newFood: function () {
      this.openFood(null)
    },
    openFood: function (index) {
      if (this.editPermission) {
        this.foodIndex = index
        let foodData = {}
        if (this.foodIndex === null) {
          foodData = {
            value: 0,
            food_id: null,
            details: '',
          }
          if (this.hasAlternativesExtra) {
            foodData.alternatives_extra = []
          }
        } else {
          foodData = {
            value: this.meal.foods[index].value,
            food_id: this.meal.foods[index].food_id,
            details: this.meal.foods[index].details,
          }
          if (this.hasAlternativesExtra) {
            foodData.alternatives_extra = this.meal.foods[index].alternatives_extra
              ? JSON.parse(this.meal.foods[index].alternatives_extra)
              : []
          }
        }
        this.food = foodData
        this.dialogFood = true
      }
    },
    newAlternativeExtra: function () {
      this.dialogFood = false
      this.$prompt(this.str['alternative'], '', '', '', Utils.getAlertOptions())
        .then((value) => {
          this.dialogFood = true
          if (value) {
            this.food.alternatives_extra.push(value)
          }
        })
        .catch(() => {
          this.dialogFood = true
        })
    },
    cancelFood: function () {
      this.foodIndex = null
      this.food = null
      this.dialogFood = false
    },
    saveFood: function () {
      if (this.foodIndex === null) {
        const foodData = {
          food_id: this.food.food_id,
          value: this.food.value,
          details: this.food.details,
        }
        if (this.hasAlternativesExtra) {
          foodData.alternatives_extra = JSON.stringify(this.food.alternatives_extra)
        }
        this.meal.foods.push(foodData)
        this.changes.push(this.changesKeys().foodAdded(this.getFoodName(this.food)))
      } else {
        this.changes.push(this.changesKeys().foodUpdated(this.getFoodName(this.meal.foods[this.foodIndex]), this.getFoodName(this.food)))
        this.meal.foods[this.foodIndex].value = this.food.value
        this.meal.foods[this.foodIndex].food_id = this.food.food_id
        this.meal.foods[this.foodIndex].details = this.food.details
        if (this.hasAlternativesExtra) {
          this.meal.foods[this.foodIndex].alternatives_extra = this.food.alternatives_extra
            ? JSON.stringify(this.food.alternatives_extra)
            : ''
        }
      }
      this.saved = false
      this.foodIndex = null
      this.food = null
      this.dialogFood = false
      this.calculateFoodPlan()
    },
    calculateFoodPlan: function () {
      const self = this
      this.$isLoading(true)
      this.getFoodPlan(true, function (foodPlan) {
        Api.calculateFoodPlan(foodPlan, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.refreshFoodPlan(response.data, true)
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      })
    },
    duplicateMeal: function (index) {
      const clone = JSON.parse(JSON.stringify(this.day.meals[index]))
      this.newMeal(clone, clone.option)
    },
    openDeleteMeal: function (index) {
      const self = this
      const meal = this.day.meals[index]
      this.$confirm(
        window.strings['want_delete_meal'] + (meal ? ' "' + Utils.normalizeName(meal.name) + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.back()
          self.deleteMeal(index)
        })
        .catch(() => {})
    },
    deleteMeal: function (index) {
      this.changes.push(this.changesKeys().recipeDeleted(this.day.meals[index].name))
      this.saved = false
      this.day.meals.splice(index, 1)
      this.calculateFoodPlan()
      this.getMealsOptions()
    },
    deleteFood: function (index) {
      this.changes.push(this.changesKeys().foodDeleted(this.getFoodName(this.meal.foods[index])))
      this.saved = false
      this.meal.foods.splice(index, 1)
      this.calculateFoodPlan()
    },
    getFoodName: function (food) {
      return this.foodData[food.food_id]
        ? food.value + this.foodTypeUnit[this.foodData[food.food_id].type].unit + ' ' + this.foodData[food.food_id].name
        : null
    },
    save: function () {
      const self = this
      this.getFoodPlan(false, function (foodPlan) {
        self.planValidation(function () {
          self.$isLoading(true)
          if (self.foodPlan.id) {
            Api.editFoodPlan(foodPlan, function (response) {
              self.$isLoading(false)
              if (response.success) {
                afterSave(response.data)
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          } else {
            Api.newFoodPlan(foodPlan, function (response) {
              self.$isLoading(false)
              if (response.success) {
                afterSave(response.data)
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          }

          function afterSave(data) {
            self.changes = []
            self.refreshFoodPlan(data)
            if (self.updateFeedbackDay && self.client) {
              self.feedbackRef.openFeedbackDialog()
            } else {
              self.back(true)
            }
          }
        })
      })
    },
    saveWithHistoric: function () {
      const self = this
      this.getFoodPlan(false, function (foodPlan) {
        self.planValidation(function () {
          self.$isLoading(true)
          if (self.alreadySavedWithHistoric) {
            Api.editFoodPlan(foodPlan, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.refreshFoodPlan(response.data)
                self.back(true)
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          } else {
            Api.newFoodPlan(foodPlan, function (response) {
              if (response.success) {
                self.alreadySavedWithHistoric = true
                self.refreshFoodPlan(response.data)
                self.finishOtherClientPlans(function () {
                  self.$isLoading(false)
                  self.back(true)
                })
              } else {
                self.$isLoading(false)
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          }
        })
      })
    },
    finishOtherClientPlans: function (callback) {
      const self = this
      const statusList = Utils.getPlanStatus()
      const activeStatus = statusList.find(function (item) {
        return item.status === 'active'
      }).value

      Api.getFoodPlans(
        {
          client_id: this.client.dbId,
          status: activeStatus,
        },
        function (response) {
          if (response.success) {
            const handlePlan = (index) => {
              if (response.data && response.data[index]) {
                if (response.data[index].id !== self.foodPlan.id) {
                  self.finishPlan(response.data[index], function () {
                    handlePlan(index + 1)
                  })
                } else {
                  handlePlan(index + 1)
                }
              } else {
                callback()
              }
            }

            handlePlan(0)
          }
        },
      )
    },
    finishPlan: function (plan, callback) {
      const statusList = Utils.getPlanStatus()
      const finishedStatus = statusList.find(function (item) {
        return item.status === 'finished'
      }).value
      plan.status = finishedStatus
      plan.client_id = this.client.dbId
      Api.editFoodPlan(plan, function () {
        if (callback) {
          callback()
        }
      })
    },
    planValidation: function (callback) {
      if (this.hasValidation && this.client) {
        const self = this
        const data = this.getClientNotes()

        const errorsIds = []
        const userExcludedFoods = []
        const countryExcludedFoods = []
        const intolerantFoods = data && data.intolerant_foods ? data.intolerant_foods : []
        const unacceptedFoods = data && data.unaccepted_foods ? data.unaccepted_foods : []
        const excludeFoodGroups = data && data.exclude_food_groups ? data.exclude_food_groups : []
        const excludeFoods = new Set([...intolerantFoods, ...unacceptedFoods])
        if (excludeFoodGroups && excludeFoodGroups.length) {
          const excludeFoodGroupsItems = this.foodsItems.filter(function (e) {
            return excludeFoodGroups.indexOf(e.food_group) > -1 || excludeFoodGroups.indexOf(e.food_group2) > -1
          })
          excludeFoodGroupsItems.forEach(function (e) {
            if (!excludeFoods.has(e.id)) {
              excludeFoods.add(e.id)
            }
          })
        }
        if (excludeFoods.size) {
          this.foodPlan.days.forEach(function (day) {
            day.meals.forEach(function (meal) {
              meal.foods.forEach(function (food) {
                if (excludeFoods.has(food.food_id)) {
                  errorsIds.push(food.food_id)
                  if (userExcludedFoods.indexOf(food.food_id) === -1) {
                    userExcludedFoods.push(food.food_id)
                  }
                }
              })
            })
          })
        }
        if (this.hasFoodsExcludedCountriesValidation && this.client.country) {
          this.foodPlan.days.forEach(function (day) {
            day.meals.forEach(function (meal) {
              meal.foods.forEach(function (food) {
                if (
                  self.foodData[food.food_id] &&
                  self.foodData[food.food_id].excluded_countries &&
                  self.foodData[food.food_id].excluded_countries.indexOf(self.client.country) > -1
                ) {
                  errorsIds.push(food.food_id)
                  if (countryExcludedFoods.indexOf(food.food_id) === -1) {
                    countryExcludedFoods.push(food.food_id)
                  }
                }
              })
            })
          })
        }
        if (
          this.hasFoodsIntolerancesValidation &&
          data &&
          ((data.food_intolerance && data.food_intolerance.length) || data.food_vegetarian)
        ) {
          try {
            let foodVegetarianIntolerance = null
            if (this.foodVegetarianItems && this.foodIntoleranceItems && data.food_vegetarian) {
              const foodVegItem = this.foodVegetarianItems.find(function (v) {
                return v.value === data.food_vegetarian
              })
              if (foodVegItem && foodVegItem.nutrition_category) {
                const foodIntItem = this.foodIntoleranceItems.find(function (t) {
                  return t.nutrition_category === foodVegItem.nutrition_category
                })
                if (foodIntItem) {
                  foodVegetarianIntolerance = foodIntItem.value
                }
              }
            }
            this.foodPlan.days.forEach(function (day) {
              day.meals.forEach(function (meal) {
                meal.foods.forEach(function (food) {
                  if (self.foodData[food.food_id] && self.foodData[food.food_id].intolerances) {
                    const intolerances = JSON.parse(self.foodData[food.food_id].intolerances)
                    if (intolerances.indexOf(data.food_intolerance) > -1 || intolerances.indexOf(foodVegetarianIntolerance) > -1) {
                      errorsIds.push(food.food_id)
                      if (userExcludedFoods.indexOf(food.food_id) === -1) {
                        userExcludedFoods.push(food.food_id)
                      }
                    }
                  }
                })
              })
            })
          } catch {}
        }
        this.errorsFoodsIds = errorsIds
        if (this.errorsFoodsIds.length && callback) {
          let str = window.strings['some_adjustments_food_plan']
          if (userExcludedFoods && userExcludedFoods.length) {
            str += '\n\n• ' + window.strings['unaccepted_intolerant_foods'] + ':'
            userExcludedFoods.forEach(function (ef, efInd) {
              if (self.foodData[ef]) {
                str += (efInd > 0 ? ', ' : ' ') + self.foodData[ef].name
              }
            })
          }
          if (countryExcludedFoods && countryExcludedFoods.length) {
            str += '\n\n• ' + window.strings['foods_not_available_country'] + ':'
            countryExcludedFoods.forEach(function (ef, efInd) {
              if (self.foodData[ef]) {
                str += (efInd > 0 ? ', ' : ' ') + self.foodData[ef].name
              }
            })
          }
          return this.$alert(str, '', 'warning', Utils.getAlertOptions())
        }

        const errorsSupplements = []
        if (data && data.include_supplements === 'Não') {
          this.foodPlan.days.forEach(function (day) {
            day.meals.forEach(function (meal) {
              const mealSupplements = self.getMealSupplements(meal)
              if (mealSupplements && mealSupplements.length) {
                mealSupplements.forEach(function (sup) {
                  if (sup && errorsSupplements.indexOf(sup.id) === -1) {
                    errorsSupplements.push(sup.id)
                  }
                })
              }
            })
          })
        }
        this.errorsSupplementsIds = errorsSupplements
        if (this.errorsSupplementsIds.length && callback) {
          return this.$alert(window.strings['not_include_supplements_client_part_plan'], '', 'warning', Utils.getAlertOptions())
        }

        const errorsRecipes = []
        const excludedRecipes = []
        const repeatedRecipes = []
        if (data && data.exclude_recipes && data.exclude_recipes.length) {
          const recipesNames = data.exclude_recipes.map(function (rec) {
            return Utils.normalizeName(rec.name)
          })
          this.foodPlan.days.forEach(function (day) {
            day.meals.forEach(function (meal) {
              const name = Utils.normalizeName(meal.name)
              if (recipesNames.indexOf(name) > -1) {
                errorsRecipes.push(name)
                if (excludedRecipes.indexOf(name) === -1) {
                  excludedRecipes.push(name)
                }
              }
            })
          })
        }
        if (this.hasRepeatedRecipesValidation) {
          const recipesList = []
          this.foodPlan.days.forEach(function (day) {
            day.meals.forEach(function (meal) {
              const name = Utils.normalizeName(meal.name)
              if (recipesList.indexOf(name) > -1) {
                errorsRecipes.push(name)
                if (repeatedRecipes.indexOf(name) === -1) {
                  repeatedRecipes.push(name)
                }
              } else {
                recipesList.push(name)
              }
            })
          })
        }
        this.errorsRecipes = errorsRecipes
        if (this.errorsRecipes.length && callback) {
          let str = window.strings['some_adjustments_food_plan']
          if (excludedRecipes && excludedRecipes.length) {
            str += '\n\n• ' + window.strings['excluded_recipes'] + ':'
            excludedRecipes.forEach(function (er, erInd) {
              str += (erInd > 0 ? ', ' : ' ') + er
            })
          }
          if (repeatedRecipes && repeatedRecipes.length) {
            str += '\n\n• ' + window.strings['duplicated_recipes'] + ':'
            repeatedRecipes.forEach(function (er, erInd) {
              str += (erInd > 0 ? ', ' : ' ') + er
            })
          }
          return this.$alert(str, '', 'warning', Utils.getAlertOptions())
        }

        if (this.hasAcceptedFoodsValidation && data && data.accepted_foods && data.accepted_foods.length) {
          this.foodPlan.days.forEach(function (day) {
            day.meals.forEach(function (meal) {
              meal.foods.forEach(function (food) {
                const index = data.accepted_foods.indexOf(food.food_id)
                if (index > -1) {
                  data.accepted_foods.splice(index, 1)
                }
              })
            })
          })
          let missingFoods = ''
          data.accepted_foods.forEach(function (id) {
            missingFoods += (self.foodData && self.foodData[id] ? self.foodData[id].name : id) + ', '
          })
          if (missingFoods && callback) {
            missingFoods = missingFoods.substring(0, missingFoods.length - 2)
            return this.$confirm(
              window.strings['no_suggested_food_added'] + missingFoods + '. ' + window.strings['do_you_want_keep_save'],
              '',
              'warning',
              Utils.getAlertOptions(true, true),
            )
              .then(() => {
                callback()
              })
              .catch(() => {})
          }
        }

        if (this.hasMacrosValidation) {
          const errorsDaysMacros = {}
          let mealMacros = {}
          let errorsDaysMacrosStr = ''
          this.foodPlan.days.forEach(function (day) {
            mealMacros = {}
            day.meals.forEach(function (meal) {
              if (!meal.type) {
                for (let h = 0; h < self.hasMacrosValidation.length; h++) {
                  const key = self.hasMacrosValidation[h].id
                  if (!mealMacros[key]) {
                    mealMacros[key] = {
                      min: meal[key],
                      max: meal[key],
                    }
                  }
                  mealMacros[key].min = mealMacros[key].min > meal[key] ? meal[key] : mealMacros[key].min
                  mealMacros[key].max = mealMacros[key].max < meal[key] ? meal[key] : mealMacros[key].max
                }
              }
            })
            for (let h = 0; h < self.hasMacrosValidation.length; h++) {
              const macroItem = self.hasMacrosValidation[h]
              if (mealMacros[macroItem.id] && mealMacros[macroItem.id].min && mealMacros[macroItem.id].max) {
                if (mealMacros[macroItem.id].max - mealMacros[macroItem.id].min > macroItem.value) {
                  const macroName = window.strings[macroItem.name] ? window.strings[macroItem.name] : macroItem.name
                  const macroUnit = window.strings[macroItem.unit] ? window.strings[macroItem.unit] : macroItem.unit
                  const keyError =
                    macroName +
                    ' (' +
                    window.strings['min'] +
                    ' ' +
                    mealMacros[macroItem.id].min +
                    ' ' +
                    macroUnit +
                    ' | ' +
                    window.strings['max'] +
                    ' ' +
                    mealMacros[macroItem.id].max +
                    ' ' +
                    macroUnit +
                    ')'
                  errorsDaysMacros[day.id] = errorsDaysMacros[day.id]
                    ? errorsDaysMacros[day.id]
                    : {
                        errors: [],
                        keys: [],
                      }
                  errorsDaysMacros[day.id].errors.push(keyError)
                  errorsDaysMacros[day.id].keys.push(macroItem.id)
                  errorsDaysMacrosStr += (errorsDaysMacrosStr ? '\n' : '') + Utils.normalizeName(day.name) + ': ' + keyError
                }
              }
            }
          })
          this.errorsDaysMacros = errorsDaysMacros
          if (errorsDaysMacrosStr && callback) {
            return this.$alert(errorsDaysMacrosStr, '', 'warning', Utils.getAlertOptions())
          }
        }
      }

      if (this.warningSaveMessage && callback) {
        return this.$confirm(this.warningSaveMessage, '', 'warning', Utils.getAlertOptions(true, true))
          .then(() => {
            callback()
          })
          .catch(() => {})
      }

      if (callback) {
        callback()
      }
    },
    getClientNotes: function () {
      let data = {}
      if (this.client) {
        try {
          const notes = JSON.parse(JSON.stringify(this.client.notes))
          data = JSON.parse(notes)
        } catch {}
      }
      return data
    },
    itemHasError: function (type, data, extraData) {
      if (
        this.hasValidation &&
        ((this.errorsFoodsIds && this.errorsFoodsIds.length) ||
          (this.errorsSupplementsIds && this.errorsSupplementsIds.length) ||
          (this.errorsDaysMacros && Object.keys(this.errorsDaysMacros).length) ||
          (this.errorsRecipes && this.errorsRecipes.length))
      ) {
        try {
          switch (type) {
            case 'day':
              if (this.errorsDaysMacros && this.errorsDaysMacros[data.id]) {
                return true
              }
              for (let m = 0; m < data.meals.length; m++) {
                for (let f = 0; f < data.meals[m].foods.length; f++) {
                  if (this.errorsFoodsIds.indexOf(data.meals[m].foods[f].food_id) > -1) {
                    return true
                  }
                }
                if (this.errorsSupplementsIds && this.errorsSupplementsIds.length) {
                  const mealSupplements = this.getMealSupplements(data.meals[m])
                  if (mealSupplements && mealSupplements.length) {
                    for (let s = 0; s < mealSupplements.length; s++) {
                      if (mealSupplements[s] && this.errorsSupplementsIds.indexOf(mealSupplements[s].id) > -1) {
                        return true
                      }
                    }
                  }
                }
                if (this.errorsRecipes && this.errorsRecipes.length) {
                  if (this.errorsRecipes.indexOf(Utils.normalizeName(data.meals[m].name)) > -1) {
                    return true
                  }
                }
              }
              break
            case 'meal':
              if (extraData && !data.type && this.errorsDaysMacros && this.errorsDaysMacros[extraData.id]) {
                return true
              }
              for (let f = 0; f < data.foods.length; f++) {
                if (this.errorsFoodsIds.indexOf(data.foods[f].food_id) > -1) {
                  return true
                }
              }
              if (this.errorsSupplementsIds && this.errorsSupplementsIds.length) {
                const mealSupplements = this.getMealSupplements(data)
                if (mealSupplements && mealSupplements.length) {
                  for (let s = 0; s < mealSupplements.length; s++) {
                    if (mealSupplements[s] && this.errorsSupplementsIds.indexOf(mealSupplements[s].id) > -1) {
                      return true
                    }
                  }
                }
              }
              if (this.errorsRecipes && this.errorsRecipes.length) {
                if (this.errorsRecipes.indexOf(Utils.normalizeName(data.name)) > -1) {
                  return true
                }
              }
              break
            case 'food':
              if (this.errorsFoodsIds.indexOf(data.food_id) > -1) {
                return true
              }
              break
            case 'supplement':
              if (this.errorsSupplementsIds && this.errorsSupplementsIds.length && this.errorsSupplementsIds.indexOf(data.id) > -1) {
                return true
              }
              break
          }
        } catch {}
      }
      return false
    },
    refreshFoodPlan: function (data, calculate) {
      this.foodPlan = data

      if (this.foodPlan.days[this.dayIndex] && this.day) {
        this.day = this.foodPlan.days[this.dayIndex]

        if (this.foodPlan.days[this.dayIndex].meals[this.mealIndex] && this.meal) {
          this.meal = this.foodPlan.days[this.dayIndex].meals[this.mealIndex]

          if ((this.showOverview || this.showMealOptionsMacros) && this.daysOverviewData && this.daysOverviewData[this.day.id]) {
            this.daysOverviewData[this.day.id] = this.getDayOverview(this.day)
          }
        }
      }

      if (!calculate) {
        Utils.setStorage('foodPlan', {
          data: this.foodPlan,
          client: this.client,
        })
      }

      if (this.showPlanMacros) {
        this.getPlanMacros()
      }

      this.planValidation()
    },
    getFoodPlan: function (calculate, callback) {
      const self = this
      const mealsImages = []

      if (this.foodPlan.days[this.dayIndex] && this.day) {
        this.foodPlan.days[this.dayIndex] = this.day

        if (this.foodPlan.days[this.dayIndex].meals[this.mealIndex] && this.meal) {
          this.foodPlan.days[this.dayIndex].meals[this.mealIndex] = this.meal
        }
      }

      if (!calculate) {
        Utils.setStorage('foodPlan', {
          data: this.foodPlan,
          client: this.client,
        })
      }

      this.foodPlan.type = 2

      if (this.foodPlan.calories_goal) {
        this.foodPlan.calories_goal = parseFloat(this.foodPlan.calories_goal)
      }
      if (this.foodPlan.protein_goal) {
        this.foodPlan.protein_goal = parseFloat(this.foodPlan.protein_goal)
      }
      if (this.foodPlan.carbs_goal) {
        this.foodPlan.carbs_goal = parseFloat(this.foodPlan.carbs_goal)
      }
      if (this.foodPlan.fat_goal) {
        this.foodPlan.fat_goal = parseFloat(this.foodPlan.fat_goal)
      }
      if (this.foodPlan.fiber_goal) {
        this.foodPlan.fiber_goal = parseFloat(this.foodPlan.fiber_goal)
      }
      if (this.foodPlan.sodium_goal) {
        this.foodPlan.sodium_goal = parseFloat(this.foodPlan.sodium_goal)
      }
      if (this.foodPlan.sugar_goal) {
        this.foodPlan.sugar_goal = parseFloat(this.foodPlan.sugar_goal)
      }

      for (let d = 0; d < this.foodPlan.days.length; d++) {
        const day = this.foodPlan.days[d]
        day.calories_goal = parseFloat(day.calories_goal)
        day.protein_goal = parseFloat(day.protein_goal)
        day.carbs_goal = parseFloat(day.carbs_goal)
        day.fat_goal = parseFloat(day.fat_goal)
        day.fiber_goal = parseFloat(day.fiber_goal)
        day.sodium_goal = parseFloat(day.sodium_goal)
        day.sugar_goal = parseFloat(day.sugar_goal)

        for (let m = 0; m < day.meals.length; m++) {
          const meal = day.meals[m]

          if (meal.extra_data && typeof meal.extra_data !== 'string') {
            meal.extra_data = JSON.stringify(meal.extra_data)
          }

          for (let f = 0; f < meal.foods.length; f++) {
            const food = meal.foods[f]
            food.value = parseFloat(food.value)
          }

          if (this.showMealImage) {
            mealsImages.push(meal)
          }
        }
      }

      if (this.client) {
        this.foodPlan.base = 0
        this.foodPlan.client_id = this.client.dbId
      } else {
        this.foodPlan.base = 1
      }

      this.foodPlan.calories = this.getPlanTotalCalories()
      this.foodPlan.days_count = this.foodPlan.days.length

      if (this.changes && this.changes.length) {
        this.foodPlan.changes = this.changes.join('\n')
      } else {
        delete this.foodPlan.changes
      }

      if (!calculate) {
        // TG - Feedback nutrition changed
        if (this.newPlanParameter) {
          this.foodPlan.new_plan = 1
        } else {
          delete this.foodPlan.new_plan
        }
        // TG - Feedback calories deficit changed
        if (this.planMacros && this.planMacros.calories && this.initialCaloriesValue) {
          try {
            const caloriesDiff = parseFloat(
              Math.round((parseFloat(this.planMacros.calories) - parseFloat(this.initialCaloriesValue)) * 100) / 100,
            )
            if (caloriesDiff !== 0) {
              this.foodPlan.calories_changes = this.foodPlan.calories_changes ? this.foodPlan.calories_changes : []
              if (typeof this.foodPlan.calories_changes === 'string') {
                this.foodPlan.calories_changes = JSON.parse(this.foodPlan.calories_changes)
              }
              this.foodPlan.calories_changes.push({
                date: moment().format('YYYY-MM-DD HH:mm:ss'),
                calories: caloriesDiff,
              })
              this.foodPlan.calories_changes = JSON.stringify(this.foodPlan.calories_changes)
            }
          } catch (error) {
            this.$isLoading(false)
            this.$alert(error.message, '', 'warning', Utils.getAlertOptions())
            return false
          }
        }
      }

      if (this.showMealImage && !calculate) {
        handleMealImage(0)
      } else {
        callback(this.foodPlan)
      }

      function handleMealImage(index) {
        if (!mealsImages[index]) {
          return callback(self.foodPlan)
        }

        if (mealsImages[index].image && !isNaN(mealsImages[index].image)) {
          return handleMealImage(index + 1)
        }

        if (!mealsImages[index].image || (mealsImages[index].image && !mealsImages[index].image.id && !mealsImages[index].image.image)) {
          mealsImages[index].image = null
          return handleMealImage(index + 1)
        }

        if (mealsImages[index].image && !mealsImages[index].image.updated) {
          mealsImages[index].image = mealsImages[index].image.id
          return handleMealImage(index + 1)
        }

        self.setMealImage(mealsImages[index].image, function (image) {
          mealsImages[index].image = image ? image.id : null
          return handleMealImage(index + 1)
        })
      }
    },
    getPlanTotalCalories: function () {
      let totalCalories = 0
      for (let d = 0; d < this.foodPlan.days.length; d++) {
        const day = this.foodPlan.days[d]
        let dayCalories = 0
        if (this.showMacrosAverage) {
          dayCalories = this.getDayMacrosAverage(day, 'calories')
        } else {
          dayCalories = day.meals[0] ? day.meals[0].calories : 0
        }
        totalCalories += dayCalories
      }
      return Math.round(totalCalories)
    },
    openDeleteFoodPlan: function () {
      if (this.meal) {
        return this.openDeleteMeal(this.mealIndex)
      }
      if (this.day) {
        return this.openDeleteDay(this.dayIndex)
      }
      this.deleteFoodPlan()
    },
    deleteFoodPlan: function () {
      const self = this
      const data = {
        id: this.foodPlan.id,
        client_id: this.client ? this.client.dbId : null,
      }

      this.$confirm(
        window.strings['want_delete_nutrition_plan'] + (this.foodPlan ? ' "' + Utils.normalizeName(this.foodPlan.name) + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteFoodPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.back(true)
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        })
        .catch(() => {})
    },
    updateGoalValues: function () {
      this.goalValues = {}
      for (let i = 0; i < this.valuesItems.length; i++) {
        const element = this.valuesItems[i]
        if (!element.hidden) {
          if (this.day) {
            this.goalValues[element.goalKey] = this.day[element.goalKey]
          } else {
            this.goalValues[element.goalKey] = this.foodPlan[element.goalKey]
          }
        }
      }
      this.dialogGoalValues = true
    },
    cancelGoalValues: function () {
      this.dialogGoalValues = false
      this.goalValues = {}
    },
    saveGoalValues: function () {
      for (const key in this.goalValues) {
        if (this.day) {
          this.day[key] = parseFloat(this.goalValues[key])
        } else {
          this.foodPlan[key] = parseFloat(this.goalValues[key])
        }
      }
      this.changes.push(this.changesKeys().goalMacrosValuesUpdated())
      this.saved = false
      this.dialogGoalValues = false
    },
    openAddSupplementDialog: function () {
      const defaultSupplement = this.supplementsItems && this.supplementsItems[0] ? this.supplementsItems[0] : null

      this.supplementToAdd = {
        id: defaultSupplement ? defaultSupplement.id : '',
        value: '',
      }
      this.dialogAddSupplement = true
    },
    closeAddSupplementDialog: function () {
      this.dialogAddSupplement = false
    },
    addSupplement: function () {
      this.validateSupplements()
      this.meal.extra_data.supplements.push(JSON.parse(JSON.stringify(this.supplementToAdd)))
      this.changes.push(this.changesKeys().supplementAdded(this.getSupplementName(this.supplementToAdd)))
      this.saved = false
      this.dialogAddSupplement = false
      this.planValidation()
    },
    deleteSupplement: function (index) {
      this.validateSupplements()
      this.changes.push(this.changesKeys().supplementDeleted(this.getSupplementName(this.meal.extra_data.supplements[index])))
      this.saved = false
      this.meal.extra_data.supplements.splice(index, 1)
    },
    getSupplementName: function (supplement) {
      return this.supplementData[supplement.id] ? supplement.value + ' ' + this.supplementData[supplement.id].name : null
    },
    validateSupplements: function () {
      if (this.showSupplements && this.meal) {
        if (this.meal.extra_data) {
          if (typeof this.meal.extra_data === 'string') {
            this.meal.extra_data = JSON.parse(this.meal.extra_data)
          }
        } else {
          this.meal.extra_data = {}
        }
        if (
          !this.meal.extra_data.supplements ||
          (this.meal.extra_data.supplements && this.meal.extra_data.supplements[0] && !this.meal.extra_data.supplements[0].id)
        ) {
          this.meal.extra_data.supplements = []
        }
      }
    },
    openFormulasDialog: function () {
      this.formulasRef.open()
    },
    updateMealImage(event) {
      const self = this
      this.$isLoading(true)

      if (event) {
        const file = event.srcElement.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result
          image.onload = function () {
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              saveImage(e.target.result)
            } else {
              saveImage(resizeImage(image))
            }
          }
        }
        reader.onerror = function () {
          self.$isLoading(false)
          self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
        }
      } else {
        saveImage(null)
      }

      function resizeImage(image) {
        const canvas = document.createElement('canvas')
        let width = image.width
        let height = image.height

        if (width > height) {
          if (width > self.imageMaxSize) {
            height *= self.imageMaxSize / width
            width = self.imageMaxSize
          }
        } else {
          if (height > self.imageMaxSize) {
            width *= self.imageMaxSize / height
            height = self.imageMaxSize
          }
        }
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)
        return canvas.toDataURL()
      }

      function saveImage(image) {
        self.$set(self.meal, 'image', {
          id: image ? self.meal.image.id : null,
          image: image,
          updated: true,
        })
        document.getElementById('meal-input-image').value = ''
        self.$isLoading(false)
      }
    },
    refreshMealImage: function () {
      const self = this

      if (!this.showMealImage || !this.meal) {
        return false
      }

      if (!this.meal.image) {
        return this.$set(this.meal, 'image', {
          id: null,
          image: '',
        })
      }

      if (!this.meal.image.image && this.meal.image && !isNaN(parseInt(this.meal.image))) {
        return this.getMealImage(this.meal.image, function (image) {
          if (image) {
            self.$set(self.meal, 'image', {
              id: self.meal.image,
              image: image.file,
            })
          } else {
            self.$set(self.meal, 'image', {
              id: null,
              image: '',
            })
          }
        })
      }
    },
    setMealImage: function (data, callback) {
      const self = this

      if (!data.image && data.id) {
        return callback(null)
      }

      const payload = {
        name: 'image_meal_' + new Date().getTime(),
        type: 1,
        file: data.image,
      }

      if (data.id) {
        payload.id = data.id
        this.$isLoading(true)
        return Api.updateImage(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            callback(response.data)
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }

      if (data.image) {
        this.$isLoading(true)
        return Api.newImage(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            callback(response.data)
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }

      return callback(null)
    },
    getMealImage: function (id, callback) {
      const self = this
      this.$isLoading(true)

      Api.getImage(id, function (response) {
        self.$isLoading(false)

        if (response.success) {
          callback(response.data[0])
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    moveAccordion: function (position, option) {
      const options = JSON.parse(JSON.stringify(this.mealsOptions))
      const index = options.findIndex(function (opt) {
        return opt === option
      })
      let newIndex = null
      if (position === 'up' && index > 0) {
        newIndex = index - 1
      }
      if (position === 'down' && index < options.length - 1) {
        newIndex = index + 1
      }
      if (newIndex !== null) {
        options.splice(index, 1)
        options.splice(newIndex, 0, option)
        this.day.meals.sort((a, b) => options.indexOf(a.option) - options.indexOf(b.option))
        this.getMealsOptions(true)
      }
    },
    getMealSupplements: function (meal) {
      try {
        if (meal.extra_data) {
          const extraData = typeof meal.extra_data === 'string' ? JSON.parse(meal.extra_data) : meal.extra_data
          if (extraData && extraData.supplements && extraData.supplements.length) {
            return extraData.supplements
          }
        }
      } catch {}
      return null
    },
    sortMealSupplements: function (data) {
      this.dragging = false
      const { newIndex, oldIndex } = data
      this.validateSupplements()
      const supplements = JSON.parse(JSON.stringify(this.meal.extra_data.supplements))
      if (oldIndex !== newIndex) {
        const [item] = supplements.splice(oldIndex, 1)
        supplements.splice(newIndex, 0, item)
      }
      this.meal.extra_data.supplements = []
      setTimeout(() => {
        this.meal.extra_data.supplements = supplements
      })
    },
    showDayOverview: function (day, open) {
      const index = this.daysOverviewOpened.indexOf(day.id)
      if (index > -1) {
        this.daysOverviewOpened.splice(index, 1)
        delete this.daysOverviewData[day.id]
        if (!open) {
          return true
        }
      }
      this.daysOverviewData[day.id] = this.getDayOverview(day)
      this.daysOverviewOpened.push(day.id)
    },
    getDayOverview: function (day) {
      const options = []
      const meals = []
      if (day && day.meals) {
        day.meals.forEach(function (meal) {
          meals.push(meal)
          if (options.indexOf(meal.option) === -1) {
            options.push(meal.option)
          }
        })
      }
      return {
        options: options,
        meals: meals,
      }
    },
    dayOverviewVisible: function (day, option) {
      for (let i = 0; i < this.daysOverviewData[day.id].meals.length; i++) {
        const meal = this.daysOverviewData[day.id].meals[i]
        if (
          meal.option === option &&
          (!this.filterTypeEnabled ||
            (this.filterTypeEnabled &&
              this.typesOptionsTabs[this.typeOptionTab] &&
              this.typesOptionsTabs[this.typeOptionTab].values &&
              this.typesOptionsTabs[this.typeOptionTab].values.indexOf(meal.type) > -1))
        ) {
          return true
        }
      }
      return false
    },
    openDayOverviewMeal: function (dayIndex, mealIndex) {
      if (this.checkPreventRecipeChanges()) {
        this.dayOverviewMealOpened = true
        if (dayIndex !== null) {
          this.openDay(dayIndex)
        }
        this.openMeal(mealIndex)
      }
    },
    resetDaysOverviews: function () {
      this.daysOverviewOpened = []
      this.daysOverviewData = {}
    },
    addDayOverviewOption: function (index) {
      this.openDay(index)
      if (
        this.filterTypeEnabled &&
        this.typesOptionsTabs[this.typeOptionTab] &&
        this.typesOptionsTabs[this.typeOptionTab].values &&
        this.typesOptionsTabs[this.typeOptionTab].values[0]
      ) {
        this.newRecipe(false, this.typesOptionsTabs[this.typeOptionTab].values[0].toString())
      } else {
        this.newRecipe()
      }
    },
    mealOpened: function () {
      if (this.showRecipes && this.preventRecipeChanges && this.meal) {
        this.recipeInitialData = JSON.parse(JSON.stringify(this.meal))
      }
    },
    copyClipboardPlan: function () {
      const keys = ['category', 'days', 'days_count', 'description', 'extra_data', 'name', 'proposal', 'video_url']
      const clone = JSON.parse(JSON.stringify(this.foodPlan))
      window.clipboardFoodPlan = {}
      keys.forEach(function (key) {
        if (clone[key]) {
          window.clipboardFoodPlan[key] = clone[key]
        }
      })
      this.$notify({
        group: 'alert',
        text: window.strings['data_copied'],
        duration: 2000,
      })
    },
    pasteClipboardPlan: function () {
      if (this.clipboardPlan) {
        const clone = JSON.parse(JSON.stringify(this.clipboardPlan))
        for (const key in clone) {
          this.foodPlan[key] = clone[key]
        }
        this.calculateFoodPlan()
      }
    },
    convertPlanToBase: function () {
      const self = this
      this.$prompt(this.str['enter_food_plan_base_name'], '', '', '', Utils.getAlertOptions())
        .then((name) => {
          self.$isLoading(true)
          self.getFoodPlan(false, function (plan) {
            plan.base = 1
            plan.name = name
            delete plan.client_id
            delete plan.calories_changes
            delete plan.new_plan
            Api.newFoodPlan(plan, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.$alert(window.strings['food_plan_base_added'], '', 'success', Utils.getAlertOptions())
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          })
        })
        .catch(() => {})
    },
    setExcludeRecipe: function (recipe, include) {
      const self = this
      try {
        if (this.$refs && this.$refs.dayNotes) {
          const recipeName = recipe && recipe.name ? Utils.normalizeName(recipe.name.toLowerCase()) : null
          if (recipeName) {
            const recipesFound = this.recipesItems.filter(function (item) {
              const name = item.name ? Utils.normalizeName(item.name.toLowerCase()) : null
              return name && name.indexOf(recipeName) > -1
            })

            if (recipesFound && recipesFound.length) {
              const save = (recipesFound, description) => {
                const notes = self.getClientNotes()
                const excludeRecipes = notes && notes.exclude_recipes ? notes.exclude_recipes : []

                for (let i = 0; i < recipesFound.length; i++) {
                  const recipeFoundName =
                    recipesFound[i] && recipesFound[i].name ? Utils.normalizeName(recipesFound[i].name.toLowerCase()) : null
                  const excludeIndex = recipeFoundName
                    ? excludeRecipes.findIndex((item) => {
                        const name = item.name ? Utils.normalizeName(item.name.toLowerCase()) : null
                        return name && name.indexOf(recipeFoundName) > -1
                      })
                    : -1

                  if (excludeIndex > -1) {
                    excludeRecipes.splice(excludeIndex, 1)
                  }
                  if (include) {
                    excludeRecipes.push({
                      id: recipesFound[i].id,
                      name: recipesFound[i].name,
                      description: description ? description : '',
                    })
                  }
                }
                notes.exclude_recipes = excludeRecipes
                self.$refs.dayNotes.updateNotes(notes, self.planValidation)
              }

              if (include) {
                this.excludeRecipeData = {
                  items: Utils.getNutritionExcludeRecipesDescription(),
                  description: '',
                  confirmCallback: function () {
                    save(recipesFound, self.excludeRecipeData.description)
                    self.dialogExcludeRecipe = false
                    self.excludeRecipeData = null
                  },
                  cancelCallback: function () {
                    self.dialogExcludeRecipe = false
                    self.excludeRecipeData = null
                  },
                }
                this.dialogExcludeRecipe = true
                return true
              }
              return save(recipesFound)
            }
          }
        }
      } catch {}

      this.$alert(window.strings['recipe_not_found'], '', 'warning', Utils.getAlertOptions())
    },
    detectNotesSaved: function (event) {
      try {
        if (event && event.data && event.data.indexOf('setImmediate') !== 0) {
          const data = JSON.parse(event.data)
          if (data && data.event === 'client-notes-saved') {
            this.planValidation()
          }
        }
      } catch {}
    },
    activeEventListener: function () {
      if (this.client && this.showUserNotes) {
        window.addEventListener('message', this.detectNotesSaved)
      }
    },
    removeEventListener: function () {
      if (this.client && this.showUserNotes) {
        window.removeEventListener('message', this.detectNotesSaved)
      }
      if (this.showHeaderFixed && this.headerFixed) {
        window.removeEventListener('scroll', this.scrollHeaderFixed)
      }
    },
    destroyValidation: function (next) {
      if ((this.showSaveOnlyInitialStep || this.showExitWarning) && this.changes.length && !this.saved && this.editPermission) {
        return this.$confirm(
          window.strings['are_you_sure_leave_food_plan_without_saving'],
          '',
          'warning',
          Utils.getAlertOptions(true, true),
        )
          .then(() => {
            Utils.setStorage('foodPlan', null)
            next()
          })
          .catch(() => {
            next(false)
          })
      }
      Utils.setStorage('foodPlan', null)
      next()
    },
    copyFood: function (food) {
      const clone = JSON.parse(JSON.stringify(food))
      delete clone.id
      delete clone.n_id
      delete clone.nd_id
      delete clone.ndm_id
      delete clone.parent
      this.cloneFoodCopied = clone
      window.foodPlanFoodClone = clone
      this.$notify({
        group: 'alert',
        text: window.strings['data_copied'],
        duration: 2000,
      })
    },
    addCloneFoodCopied: function () {
      if (this.hasRepeatedFoodsValidation) {
        const alreadyAdded = this.meal.foods.find((f) => f.food_id === this.cloneFoodCopied.food_id)
        if (alreadyAdded) {
          this.$alert(window.strings['food_already_added'], '', 'warning', Utils.getAlertOptions())
          return
        }
      }
      this.meal.foods.push(this.cloneFoodCopied)
      this.changes.push(this.changesKeys().foodPasted(this.getFoodName(this.cloneFoodCopied)))
      this.saved = false
      this.calculateFoodPlan()
    },
    activeHeaderFixed: function () {
      this.scrollHeaderFixed()
      window.addEventListener('scroll', this.scrollHeaderFixed)
    },
    scrollHeaderFixed: function () {
      this.headerFixedActive = window.scrollY > 0
      try {
        if (this.headerFixedActive) {
          const menu = document.getElementsByClassName('v-navigation-drawer')[0]
          if (menu.classList.contains('v-navigation-drawer--open')) {
            this.headerFixedWidth = window.innerWidth - menu.clientWidth - 10 + 'px'
            return true
          }
        }
      } catch {}
      this.headerFixedWidth = null
    },
    setCaloricDeficit: function () {
      if (this.showCaloricDeficit) {
        const notes = this.getClientNotes()
        if (notes && notes.caloric_deficit) {
          this.caloricDeficit = notes.caloric_deficit
        }
      }
    },
    setExcludeFood: function (id, include) {
      const self = this
      try {
        if (this.$refs && this.$refs.mealNotes) {
          const notes = self.getClientNotes()
          if (!notes.unaccepted_foods) {
            notes.unaccepted_foods = []
          }
          const index = notes.unaccepted_foods.indexOf(id)
          if (include) {
            if (index === -1) {
              notes.unaccepted_foods.push(id)
            }
          } else {
            if (index > -1) {
              notes.unaccepted_foods.splice(index, 1)
            }
          }
          self.$refs.mealNotes.updateNotes(notes, self.planValidation)
        }
      } catch {}
    },
    changeAddFood: function () {
      try {
        const type = this.foodTypeUnit[this.foodData[this.food.food_id].type]
        if (type.disable_macros) {
          this.food.value = 0
        }
        if (type.hide_quantity) {
          this.food.value = 1
        }
      } catch {}
    },
    customFoodsFilter: function (item, queryText, itemText) {
      const normalizeText = (text) =>
        text
          ?.toString()
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
      const normalizedItemText = normalizeText(itemText)
      const normalizedQueryText = normalizeText(queryText)
      return normalizedItemText.includes(normalizedQueryText)
    },
    openAutoMealsAdjustment: function () {
      const self = this
      const items = []
      this.autoMealsAdjustmentData = null
      this.foodPlan.days.forEach(function (day) {
        const defaultOption =
          day && day.meals
            ? day.meals.find(function (m) {
                return m.type === 0
              })
            : null
        if (defaultOption) {
          let info = ''
          self.valuesItems.forEach(function (v) {
            if (info !== '') {
              info += ' | '
            }
            info += v.title + ': ' + defaultOption[v.currentKey]
          })
          items.push({
            name: day.name,
            value: defaultOption.calories,
            info: info,
            option: defaultOption,
          })
        }
      })
      if (items.length) {
        this.autoMealsAdjustmentData = items
        this.dialogAutoMealsAdjustment = true
      } else {
        this.$alert(window.strings['no_defined_meals'], '', 'warning', Utils.getAlertOptions())
      }
    },
    closeAutoMealsAdjustment: function () {
      this.dialogAutoMealsAdjustment = false
    },
    autoMealsAdjustmentValid: function () {
      if (this.autoMealsAdjustmentData) {
        for (let i = 0; i < this.autoMealsAdjustmentData.length; i++) {
          if (!this.autoMealsAdjustmentData[i].value) {
            return false
          }
        }
        return true
      }
      return false
    },
    applyAutoMealsAdjustment: function () {
      const self = this
      try {
        this.closeAutoMealsAdjustment()
        this.$isLoading(true)
        const caloriesDiff = this.hasMacrosValidation
          ? this.hasMacrosValidation.find(function (c) {
              return c.id === 'calories'
            })
          : null
        const proteinDiff = this.hasMacrosValidation
          ? this.hasMacrosValidation.find(function (c) {
              return c.id === 'protein'
            })
          : null
        const foodFields = ['food_id', 'type', 'value', 'calories', 'protein', 'carbs', 'fat']
        const options = []
        const mealsCalories = {}
        this.autoMealsAdjustmentData.forEach(function (d) {
          mealsCalories[d.name] = d.value
        })
        this.foodPlan.days.forEach(function (day) {
          const defaultOption =
            day && day.meals
              ? day.meals.find(function (m) {
                  return m.type === 0
                })
              : null
          if (defaultOption) {
            day.meals.forEach(function (meal) {
              if (meal.foods) {
                const obj = {
                  id: day.id + '-' + meal.id,
                  parent: defaultOption.option === meal.option ? true : false,
                  calories: parseFloat(mealsCalories[day.name]),
                  calories_diff: caloriesDiff.value,
                  protein: defaultOption.protein,
                  protein_diff: proteinDiff.value,
                  foods: [],
                }
                meal.foods.forEach(function (food) {
                  const foodObj = {}
                  foodFields.forEach(function (key) {
                    foodObj[key] = food[key]
                  })
                  obj.foods.push(foodObj)
                })
                obj.foods = obj.foods.sort((a, b) => b.type - a.type)
                options.push(obj)
              }
            })
          }
        })
        Api.autoMealAdjustment(options, function (response) {
          if (response && response.data) {
            let alertMessage = ''
            let needsCalculate = false
            response.data.forEach(function (item) {
              const split = item.id.split('-')
              const dayId = parseInt(split[0])
              const mealId = parseInt(split[1])
              const day = self.foodPlan.days.find(function (d) {
                return d.id === dayId
              })
              const meal =
                day && day.meals
                  ? day.meals.find(function (d) {
                      return d.id === mealId
                    })
                  : null
              if (!item.success && item.message) {
                if (alertMessage) {
                  alertMessage += '</br></br>'
                }
                alertMessage +=
                  (day ? Utils.normalizeName(day.name) + ' - ' : '') + (meal ? Utils.normalizeName(meal.option) + ': ' : '') + item.message
              }
              if (meal) {
                needsCalculate = true
                meal.foods.forEach(function (food) {
                  const foodSrc = item.foods.find(function (s) {
                    return s.food_id === food.food_id
                  })
                  if (foodSrc) {
                    food.value = foodSrc.value
                  }
                })
              }
            })
            if (needsCalculate) {
              self.calculateFoodPlan()
            } else {
              self.$isLoading(false)
            }
            if (alertMessage) {
              self.$alert('', '', 'warning', Utils.getAlertOptions(false, false, alertMessage))
            }
          } else {
            self.$isLoading(false)
          }
        })
      } catch (error) {
        this.$isLoading(false)
      }
    },
    exportPdf: function () {
      const self = this
      let html = `
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${this.foodPlan.name}</title>
            <style>
                body {
                  font-family: Arial, sans-serif;
                  font-size: 12px;
                  color: #333;
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 15px;
                  border-top: 1px solid #ddd;
                  border-left: 1px solid #ddd;
                }
                tr {
                  border-bottom: 1px solid #ddd;
                }
                td, th {
                  border-right: 1px solid #ddd;
                  padding: 8px;
                  font-size: 12px;
                  vertical-align: top;
                }
                th {
                  text-align: center;
                }
                td {
                  text-align: left;
                }
            </style>
          </head>
          <body>
        `
      if (this.showExportPdf.header) {
        html += `<div><img src="${this.showExportPdf.header}"</div>`
      }
      html += `<table>
          <tr>
            <th>
              <span style="transform: translateY(9px);">${window.strings['meal']}</span>
            </th>
            <th>
              <span style="transform: translateY(9px);">${window.strings['foods']}</span>
            </th>
            <th>
              <span style="transform: translateY(9px);">${window.strings['supplements']}</span>
            </th>
          </tr>
        `
      if (this.foodPlan.days && this.foodPlan.days.length) {
        this.foodPlan.days.forEach(function (day) {
          html += `<tr><td colspan="3" style="text-align: center;"><b style="transform: translateY(9px);">${day.name}</b></td></tr>`
          const mealsAdded = []
          day.meals.forEach(function (meal) {
            if (mealsAdded.indexOf(meal.name) === -1) {
              mealsAdded.push(meal.name)
              html += `<tr><td><span style="transform: translateY(9px);">${meal.name}</span></td><td>`
              const mealOptions = day.meals.filter(function (f) {
                return f.name === meal.name
              })
              mealOptions.forEach(function (mealOption, index) {
                if (mealOption.foods && mealOption.foods.length) {
                  if (mealOption.option) {
                    html += `<div style="${index > 0 ? 'padding-top: 5px;' : 'padding-top: 9px;'}"><span style="text-decoration: underline;">${window.strings['option']} ${mealOption.option}</span></div>`
                  }
                  for (let l = 0; l < mealOption.foods.length; l++) {
                    const food = mealOption.foods[l]
                    let unit = ''
                    let foodSrc = {}
                    if (self.foodData[food.food_id]) {
                      foodSrc = self.foodData[food.food_id]
                    }
                    if (self.foodTypeUnit[foodSrc.type] && self.foodTypeUnit[foodSrc.type].unit) {
                      unit = self.foodTypeUnit[foodSrc.type].unit
                    }
                    html += `<div style="${!mealOption.option && l === 0 ? 'padding-top: 9px;' : ''}">• ${food.value} ${unit} ${foodSrc.name}</div>`
                  }
                }
              })
              html += `</td><td>`
              mealOptions.forEach(function (mealOption, index) {
                if (mealOption.extra_data) {
                  try {
                    const supplements = JSON.parse(mealOption.extra_data).supplements
                    if (supplements && supplements.length) {
                      if (mealOption.option) {
                        html += `<div style="${index > 0 ? 'padding-top: 5px;' : 'padding-top: 9px;'}"><span style="text-decoration: underline;">${window.strings['option']} ${mealOption.option}</span></div>`
                      }
                      for (let m = 0; m < supplements.length; m++) {
                        const supplement = self.supplementData[supplements[m].id]
                        if (supplement) {
                          html += `<div style="${!mealOption.option && m === 0 ? 'padding-top: 9px;' : ''}">• ${supplement.name} - ${supplements[m].value}</div>`
                        }
                      }
                    }
                  } catch {}
                }
              })
              html += `</td></tr>`
            }
          })
        })
        html += `</table>`
      }
      html += `</body></html>`

      const tempDiv = document.createElement('div')
      tempDiv.classList.add('html-pdf-container')
      tempDiv.innerHTML = html
      document.body.appendChild(tempDiv)
      html2pdf()
        .from(tempDiv)
        .set({
          margin: 1,
          filename: 'documento.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .save()
        .then(() => {
          document.body.removeChild(tempDiv)
        })
    },
    changesKeys: function () {
      const self = this
      return {
        dayAdded: function (day) {
          if (self.showRecipes) {
            return '• ' + window.strings['meal_added'] + (day ? ' ' + day : '')
          } else {
            return '• ' + window.strings['day_added'] + (day ? ' ' + day : '')
          }
        },
        dayDuplicated: function (day) {
          if (self.showRecipes) {
            return '• ' + window.strings['meal_duplicated'] + (day ? ' ' + day : '')
          } else {
            return '• ' + window.strings['day_duplicated'] + (day ? ' ' + day : '')
          }
        },
        dayDeleted: function (day) {
          if (self.showRecipes) {
            return '• ' + window.strings['meal_deleted'] + (day ? ' ' + day : '')
          } else {
            return '• ' + window.strings['day_deleted'] + (day ? ' ' + day : '')
          }
        },
        recipeAdded: function (recipe) {
          return (
            '• ' +
            window.strings['recipe_added'] +
            ' "' +
            recipe +
            '" ' +
            window.strings['in_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        recipeUpdated: function (recipe) {
          const oldRecipe = self.meal
            ? self.meal.name
            : self.recipeIndexToUpdate || self.recipeIndexToUpdate === 0
              ? self.day.meals[self.recipeIndexToUpdate].name
              : ''
          return (
            '• ' +
            window.strings['recipe'] +
            ' "' +
            oldRecipe +
            '" ' +
            window.strings['changed_for'] +
            ' "' +
            recipe +
            '" ' +
            window.strings['in_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        recipeDeleted: function (recipe) {
          return (
            '• ' +
            window.strings['recipe_deleted'] +
            ' "' +
            recipe +
            '" ' +
            window.strings['in_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        recipesPackAdded: function (pack) {
          return (
            '• ' +
            window.strings['recipe_pack_added'] +
            ' "' +
            pack +
            '" ' +
            window.strings['in_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        foodAdded: function (food) {
          return (
            '• ' +
            window.strings['food_added'] +
            ' "' +
            food +
            '" ' +
            window.strings['in_the_recipe'] +
            ' "' +
            (self.meal ? self.meal.name : '-') +
            '" ' +
            window.strings['of_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        foodUpdated: function (oldFood, newFood) {
          return (
            '• ' +
            window.strings['food'] +
            ' "' +
            oldFood +
            '" alterado para "' +
            newFood +
            '" ' +
            window.strings['in_the_recipe'] +
            ' "' +
            (self.meal ? self.meal.name : '-') +
            '" ' +
            window.strings['of_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        foodDeleted: function (food) {
          return (
            '• ' +
            window.strings['food_deleted'] +
            ' "' +
            food +
            '" ' +
            window.strings['in_the_recipe'] +
            ' "' +
            (self.meal ? self.meal.name : '-') +
            '" ' +
            window.strings['of_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        foodPasted: function (food) {
          return (
            '• ' +
            window.strings['food_pasted'] +
            ' "' +
            food +
            '" ' +
            window.strings['in_the_recipe'] +
            ' "' +
            (self.meal ? self.meal.name : '-') +
            '" ' +
            window.strings['of_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        goalMacrosValuesUpdated: function () {
          return '• ' + window.strings['macro_goal_updated']
        },
        supplementAdded: function (supplement) {
          return (
            '• ' +
            window.strings['supplement_added'] +
            ' "' +
            supplement +
            '" ' +
            window.strings['in_the_recipe'] +
            ' "' +
            (self.meal ? self.meal.name : '-') +
            '" ' +
            window.strings['of_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
        supplementDeleted: function (supplement) {
          return (
            '• ' +
            window.strings['supplement_deleted'] +
            ' "' +
            supplement +
            '" ' +
            window.strings['in_the_recipe'] +
            ' "' +
            (self.meal ? self.meal.name : '-') +
            '" ' +
            window.strings['of_the_meal'] +
            ' "' +
            (self.day ? self.day.name : '-') +
            '"'
          )
        },
      }
    },
    clientFeedbackMounted() {
      this.feedbackRef = this.$refs.feedback
    },
    refreshClientFormulas(type, key, value) {
      this[type][key] = value
    },
    clientFormulasMounted() {
      this.formulasRef = this.$refs.formulas
    },
    clientFeedbackDestroy() {
      this.back(true)
    },
    mounted: function () {
      this.checkMountedCallback()
    },
    checkMountedCallback: function () {
      if (this.mountedCallback) {
        this.mountedCallback()
      }
    },
  },
}
</script>
